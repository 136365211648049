<div *ngIf="params?.value?.text && params?.value?.text?.length; else noDate">
  <span class="maintext">
    {{ params?.value?.text | date: 'dd-MM-yyyy' }}
  </span> &nbsp;&nbsp;&#x2022;
  <span class="subtext">{{getMoment(params?.value?.text)}}</span>
</div>
<ng-template #noDate>
  <div class="maintext">
    -
  </div>
</ng-template>
