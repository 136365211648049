export const RFQ_STATUS_MAPPER = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  EDIT_STATUS: 'EDIT_STATUS',
  DRAFT: 'DRAFT',
  RFQ_SENT: 'RFQ_SENT',
  DRAWINGS_VIEWED: 'DRAWINGS_VIEWED',
  SUPPLIER_NOTIFIED: 'SUPPLIER_NOTIFIED',
  RFQ_CANCELLED: 'RFQ_CANCELLED',
  SUPPLIER_RESPONDED: 'SUPPLIER_RESPONDED',
  QUOTE_ACCEPTED: 'QUOTE_ACCEPTED',
  SUPPLIER_REGRET: 'SUPPLIER_REGRET',
  QUOTE_REJECTED: 'QUOTE_REJECTED',
  PARTIALLY_QUOTED: 'PARTIALLY_QUOTED'
};


export const RFQ_STATUS_DROPDOWN_LIST = {
  CREATE: {
    label: 'Create',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  UPDATE: {
    label: 'Update',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  DELETE: {
    label: 'Delete',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  EDIT_STATUS: {
    label: 'Edit Status',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  DRAFT: {
    label: 'Draft',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  RFQ_SENT: {
    label: 'RFQ Sent',
    color: 'rgb(74,74,74)',
    background: 'rgb(228,228,228)',
  },
  DRAWINGS_VIEWED: {
    label: 'Drawings Viewed',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  SUPPLIER_NOTIFIED: {
    label: 'Supplier Notified',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  RFQ_CANCELLED: {
    label: 'Cancel/Unsend RFQ',
    color: 'rgb(237, 141, 23)',
    background: 'rgb(252, 238, 220)',
  },
  SUPPLIER_RESPONDED: {
    label: 'Supplier Responded',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(219, 232, 232)',
  },
  QUOTE_ACCEPTED: {
    label: 'Quote Accepted',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  SUPPLIER_REGRET: {
    label: 'Supplier Regret',
    color: 'rgb(77, 110, 179)',
    background: 'rgb(228, 233, 244)',
  },
  QUOTE_REJECTED: {
    label: 'Quote Rejected',
    color: 'rgb(204, 75, 36)',
    background: 'rgb(247, 228, 222)',
  },
  PARTIALLY_QUOTED: {
    label: 'RFQ Partially Quoted',
    color: 'rgb(102, 108, 231)',
    background: 'rgb(232,233,251)',
  }
};

export const RFQ_STATUS_TYPES = [
  { name: "Open", _id: "OPEN" },
  { name: "Closed", _id: "CLOSED" }
];

export const RFQ_STATUS_FILTER = [
  { name: "RFQ Sent", _id: "RFQ_SENT" },
  { name: "Supplier Notified", _id: "SUPPLIER_NOTIFIED" },
  { name: "Cancel/Unsend RFQ", _id: "RFQ_CANCELLED" },
  { name: "Supplier Responded", _id: "SUPPLIER_RESPONDED" },
  { name: "Quote Accepted", _id: "QUOTE_ACCEPTED" },
  { name: "Supplier Regret", _id: "SUPPLIER_REGRET" },
  { name: "Quote Rejected", _id: "QUOTE_REJECTED" },
  { name: "RFQ Partially Quoted", _id: "PARTIALLY_QUOTED" },
];
