import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

export interface ToastData {
  show?: boolean;
  progressWidth?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * Track custom toaster state
   */
  private showAccessPermission = new BehaviorSubject<Boolean>(false);
  /**
   * Custom toaster Observable
   */
  public showAccessPermission$ = this.showAccessPermission.asObservable();

  data: ToastData;

  constructor(private _toaster: ToastrService) {}

  showAccessPermissionToaster() {
    this.data = { show: true, progressWidth: '100%' };
    this.showAccessPermission.next(true);
  }

  hideAccessPermissionToaster() {
    this.data = { ...this.data, show: false };
    this.showAccessPermission.next(false);
  }

  successMessage({ title = 'Success', message = '' }) {
    this._toaster.success(message, title);
  }

  warningMessage({ title = 'Warning', message = '' }) {
    return this._toaster.warning(message, title);
  }

  errorMessage({ title = 'Error', message = '' }) {
    return this._toaster.error(message, title);
  }

  infoMessage({ title = 'Info', message = '' }) {
    return this._toaster.info(message, title);
  }
}
