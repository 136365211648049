export const STATUS = {
  WON: {
    label: 'Won',
    color: 'rgb(32, 128, 32)',
    background: 'rgb(205, 227, 205)',
  },
  LOST: {
    label: 'Lost',
    title: 'Offer Lost',
    color: 'rgb(201, 0, 0)',
    background: 'rgb(242, 210, 200)',
  },
  REGRET: {
    label: 'Regret',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  HOLD: {
    label: 'Hold',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
    title: 'Offer On Hold',
  },
  OFFER_GENERATED: {
    label: 'Offer Generated',
    title: 'Offer Generation',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  REOPEN: {
    label: 'Reopen',
    title: 'Reopen',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  PENDING_CLARIFICATION: {
    label: 'Pending Clarification',
    title: 'Pending Clarification',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  PENDING_WITH_SALES: {
    label: 'Pending with sales',
    title: 'Pending with sales',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  PENDING_WITH_SUPPLY: {
    label: 'Pending with supply',
    title: 'Pending with supply',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  ENQUIRY_CREATED: {
    label: 'Enquiry Created',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  NO_STATUS: {
    label: 'No Status',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  DEFAULT: {
    label: 'Default',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  MIGRATED: {
    label: 'Migrated',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  IN_DISCUSSIONS: {
    label: 'In discussions',
    title: 'In discussions',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  TRYING_TO_CLOSE: {
    label: 'Trying to close',
    title: 'Trying to close',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  NEGOTIATIONS: {
    label: 'Negotiations',
    title: 'Negotiations',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  PO_EXPECTED_IN_NEXT_45_DAYS: {
    label: 'PO expected in next 45 days',
    title: 'PO expected in next 45 days',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  NOT_DONE_CANCELLED: {
    label: 'Not done/cancelled',
    title: 'Not done/cancelled',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  EARLY_STAGE: {
    label: 'Early Stage',
    title: 'Early Stage',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  COMMERCIAL_STAGE: {
    label: 'Commercial Stage',
    title: 'Commercial Stage',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  CLOSURE_STAGE: {
    label: 'Closure Stage',
    title: 'Closure Stage',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  EMAIL_CONFIRMATION: {
    label: 'Email Confirmation',
    title: 'Email Confirmation',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  PO_RECEIVED: {
    label: 'PO Received',
    title: 'PO Received',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  CREDIT_UNDERWRITING: {
    label: 'Credit Underwriting',
    title: 'Credit Underwriting',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  DROPPED: {
    label: 'Dropped',
    title: 'Offer Dropped',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  EOI_RECEIVED: {
    label: 'EOI Received',
    title: 'EOI Received',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  EOI_SUBMITTED: {
    label: 'EOI Submitted',
    title: 'EOI Submitted',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  TENDER_SOURCED: {
    label: 'Tender Sourced',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  PENDING_WITH_ESTIMATION: {
    label: 'Pending with estimation',
    title: 'Pending with estimation',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  PENDING_WITH_TENDER_TEAM: {
    label: 'Pending with Tender team',
    title: 'Pending with Tender team',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  PENDING: {
    label: 'Pending',
    title: 'Pendingn',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  TENDER_BID_OPENING_AND_UPDATES: {
    label: 'Tender Bid Opening and Updates',
    title: 'Tender Bid Opening and Updates',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  FINANCIAL_BID_OPENING_AND_UPDATES: {
    label: 'Financial Bid Opening and Updates',
    title: 'Financial Bid Opening and Updates',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  RE_TENDERED: {
    label: 'Re-Tendered',
    title: 'Re-Tendered',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  CANCELLED: {
    label: 'Cancelled',
    title: 'Cancelled',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  TENDER_RECEIVED: {
    label: 'Tender Received',
    title: 'Tender Received',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  TECHNICAL_BID_OPENING_AND_UPDATES: {
    label: 'Technical Bid Opening and Updates',
    title: 'Technical Bid Opening and Updates',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  COMMERCIAL_BID_OPENING_AND_UPDATES: {
    label: 'Commercial Bid Opening and Updates',
    title: 'Commercial Bid Opening and Updates',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  FINANCIAL_COMMERCIAL_BID_OPENING_AND_UPDATES: {
    label: 'Financial/Commercial Bid Opening and Updates',
    title: 'Financial/Commercial Bid Opening and Updates',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  REVERSE_AUCTION: {
    label: 'Reverse Auction',
    title: 'Reverse Auction',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  CHECKED: {
    label: 'Checked',
    title: 'Checked',
    color: 'rgb(125, 125, 125)',
    background: 'rgb(225, 228, 233)',
  },
  SUPPLIER_ASSIGNED: {
    label: 'Supplier Assigned',
    title: 'Supplier Assigned',
    color: 'rgb(102, 108, 231)',
    background: 'rgb(224, 226, 250)',
  },
  SUPPLIER_QUERY: {
    label: 'Supplier Query',
    title: 'Supplier Query',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  SUPPLIER_NOTIFIED: {
    label: 'Supplier Notified',
    title: 'Supplier Notified',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  SUPPLIER_ACCEPTED: {
    label: 'Supplier Accepted',
    title: 'Supplier Accepted',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  LEAD_CONTRACTOR_IDENTIFIED: {
    label: 'Lead Contractor Identified',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  MOU_SUBMITTED: {
    label: 'MOU Submitted',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  R_COM_APPROVAL_DONE: {
    label: 'R Com Approval Done',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  M_COM_APPROVAL_DONE: {
    label: 'M Com Approval Done',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  CHANGE_IN_TENDER_AWARDING_DATE: {
    label: 'Change in Tender Awarding Date',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  MANAGEMENT_APPROVAL: {
    label: 'Management Approval',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  ENQUIRY_ON_HOLD: {
    label: 'Enquiry On Hold',
    title: 'Enquiry On Hold',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  OFFER_SUBMITTED: {
    label: 'Offer Submitted',
    title: 'Offer Submitted',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  NDA_APPROVAL_PENDING: {
    label: 'NDA Approval Pending',
    title: 'NDA Approval Pending',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  CREDIT_VERIFICATION_PENDING: {
    label: 'Credit Verification Pending',
    title: 'Credit Verification Pending',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  CREDIT_VERIFICATION_DONE: {
    label: 'Credit Verification Done',
    title: 'Credit Verification Done',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  ENQUIRY_GENERATED: {
    label: 'Enquiry Generated',
    title: 'Enquiry Generated',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  REQUIREMENT_GENERATED: {
    label: 'Requirement Generated',
    title: 'Requirement Generated',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  SAMPLE_APPROVAL_CYCLE: {
    label: 'Sample Approval Cycle',
    title: 'Sample Approval Cycle',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  SAMPLE_APPROVED: {
    label: 'Sample Approved',
    title: 'Sample Approved',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  SUPPLIER_REGRETTED: {
    label: 'Supplier Regretted',
    title: 'Supplier Regretted',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  INITIAL_COSTING: {
    label: 'Initial Costing',
    title: 'Initial Costing',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)'
  },
  QUALITY_APPROVAL: {
    label: 'Quality Approval',
    title: 'Quality Approval',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  SAMPLING: {
    label: 'Sampling',
    title: 'Sampling',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  FINAL_COSTING_NEGOTIATION: {
    label: 'Final Costing/Negotiation',
    title: 'Final Costing/Negotiation',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  REQUIREMENTS_RECEIVED: {
    label: 'Requirements Received',
    title: 'Requirements Received',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  CONVERTED_TO_ENQUIRY: {
    label: 'Converted to Enquiry',
    title: 'Converted to Enquiry',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  PARTIALLY_WON: {
    label: 'Partially Won',
    title: 'Partially Won',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  CLOSED: {
    label: 'Closed',
    title: 'Closed',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  ASSIGNED_TO_CMU: {
    label: 'Assigned to CMU',
    title: 'Assigned to CMU',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  ACCEPTED_BY_CMU: {
    label: 'Accepted by CMU',
    title: 'Accepted by CMU',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  REJECTED_BY_CMU: {
    label: 'Rejected by CMU',
    title: 'Rejected by CMU',
    color: 'rgb(228, 117, 86)',
    background: 'rgb(250, 227, 221)',
  },
  ESTIMATION_STARTED: {
    label: 'Estimation Started',
    title: 'Estimation Started',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  SUBMITTED_FOR_APPROVAL: {
    label: 'Submitted For Approval',
    title: 'Submitted For Approval',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  PROPOSAL_SUBMITTED: {
    label: 'Proposal Submitted',
    title: 'Proposal Submitted',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  RE_TENDERING: {
    label: 'Re-Tendering',
    title: 'Re-Tendering',
    color: 'rgb(102, 109, 230)',
    background: 'rgb(224, 226, 250)',
  },
  EARLY_ENQUIRY: {
    label: 'Early Enquiry',
    title: 'Early Enquiry',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  WAITING_FOR_PO: {
    label: 'Waiting For PO',
    title: 'Waiting For PO',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  REJECTED_BY_RISK_TEAM: {
    label: 'Rejected By Risk Team',
    title: 'Rejected By Risk Team',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  REJECTED_BY_INTERNAL_TEAM: {
    label: 'Rejected By Internal Team',
    title: 'Rejected By Internal Team',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  IN_DISCUSSION: {
    label: 'In discussion',
    title: 'In discussion',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  SEND_RFQ: {
    label: 'Send RFQ',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  ACCEPTED: {
    label: 'Accepted',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  REJECTED: {
    label: 'Rejected',
    color: 'rgb(201, 0, 0)',
    background: 'rgb(242, 210, 200)',
  },
  CONFIRMED: {
    label: 'Confirmed',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  STATUS: {
    label: 'Status',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  REMARK: {
    label: 'Remark',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  OFFER_ACCEPTED: {
    label: 'Offer Accepted',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  RFQ_RELEASED: {
    label: 'RFQ Released',
    title: 'RFQ Released',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  ASSIGNED_TO_BU: {
    label: 'Assigned To BU',
    title: 'Assigned To BU',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  'EOI/BQ_SUBMITTED': {
    label: 'EOI/BQ Submitted',
    title: 'EOI/BQ Submitted',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  BID_SUBMITTED: {
    label: 'BID Submitted',
    title: 'BID Submitted',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  BID_RESULTS_DECLARED: {
    label: 'BID Results Declared',
    title: 'BID Results Declared',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  STRATEGIC_PARTNERSHIP: {
    label: 'Strategic Partnership',
    title: 'Strategic Partnership',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  QUERY_RECEIVED: {
    label: 'Query Received',
    title: 'Query Received',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  ENQUIRY_RECEIVED: {
    label: 'Enquiry Received',
    title: 'Enquiry Received',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)'
  },
  DESIGN_AND_DEVELOPMENT: {
    label: 'Design and Development',
    title: 'Design and Development',
    color: 'rgb(125, 125, 125)',
    background: 'rgb(225, 228, 233)'
  },
  UNDER_TECHNICAL_BID_EVALUATION: {
    label: 'Under Technical Bid Evaluation',
    title: 'Under Technical Bid Evaluation',
    color: 'rgb(144, 128, 126)',
    background: 'rgb(233, 230, 229)',
  },
  ORDER_CANCELLED: {
    label: 'Order Cancelled',
    title: 'Order Cancelled',
    color: 'rgb(201, 0, 0)',
    background: 'rgb(242, 210, 200)',
  },
  PENDING_WITH_ESTIMATIONS: {
    label: 'Pending with Estimations',
    title: 'Pending with Estimations',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  PENDING_WITH_BUYER: {
    label: 'Pending with Buyer',
    title: 'Pending with Buyer',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
  QUOTE_RECEIVED: {
    label: 'Quote Received',
    title: 'Quote Received',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  REQUOTE: {
    label: 'Requote',
    title: 'Requote',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  PENDING_CREDIT_EVALUATION: {
    label: 'Pending Credit Evaluation',
    title: 'Pending Credit Evaluation',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  HOT: {
    label: 'Hot',
    title: 'Hot',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  COLD: {
    label: 'Cold',
    title: 'Cold',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  PARTIAL_OFFER_GENERATED: {
    label: 'Partial Offer Generated',
    title: 'Partial Offer Generated',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  FORECAST_TO_CLOSE: {
    label: 'Forecast to Close',
    title: 'Forecast to Close',
    color: 'rgb(32, 140, 32)',
    background: 'rgb(205, 227, 205)',
  },
  OFFER_PREPARATION_IP: {
    label: 'Offer Preparation IP',
    title: 'Offer Preparation IP',
    color: 'rgb(102, 108, 231)',
    background: 'rgb(224, 226, 250)',
  },
  OFFER_SUBMITTED_TO_CUSTOMER: {
    label: 'Offer Submitted to Customer',
    title: 'Offer Submitted to Customer',
    color: 'rgb(17, 102, 103)',
    background: 'rgb(207, 224, 225)',
  },
  DISCUSSIONS: {
    label: 'Discussions',
    title: 'Discussions',
    color: 'rgb(9, 159, 178)',
    background: 'rgb(206, 236, 240)',
  },
  NO_RESPONSE: {
    label: 'No Response',
    title: 'No Response',
    color: 'rgb(125, 125, 125)',
    background: 'rgb(225, 228, 233)',
  },
  INTERNAL_ESTIMATION: {
    label: 'Internal Estimation',
    title: 'Internal Estimation',
    color: 'rgb(230, 133, 0)',
    background: 'rgb(251, 227, 197)',
  },
  PRODUCTION_REQUOTE: {
    label: 'Production Requote',
    title: 'Production Requote',
    color: 'rgb(146, 90, 157)',
    background: 'rgb(233, 222, 235)',
  },
};

export const STATUS_MAPPER = {
  WON: 'WON',
  LOST: 'LOST',
  REGRET: 'REGRET',
  HOLD: 'HOLD',
  OFFER_GENERATED: 'OFFER GENERATED',
  PENDING_CLARIFICATION: 'PENDING CLARIFICATION',
  PENDING_WITH_SALES: 'PENDING WITH SALES',
  ENQUIRY_CREATED: 'ENQUIRY CREATED',
  NO_STATUS: 'NO STATUS',
  PENDING_WITH_SUPPLY: 'PENDING WITH SUPPLY',
  IN_DISCUSSIONS: 'IN DISCUSSIONS',
  TRYING_TO_CLOSE: 'TRYING TO CLOSE',
  NEGOTIATIONS: 'NEGOTIATIONS',
  PO_EXPECTED_IN_NEXT_45_DAYS: 'PO EXPECTED IN NEXT 45 DAYS',
  NOT_DONE_CANCELLED: 'NOT DONE/CANCELLED',
  EARLY_STAGE: 'EARLY STAGE',
  COMMERCIAL_STAGE: 'COMMERCIAL STAGE',
  CLOSURE_STAGE: 'CLOSURE STAGE',
  EMAIL_CONFIRMATION: 'EMAIL CONFIRMATION',
  PO_RECEIVED: 'PO RECEIVED',
  CREDIT_UNDERWRITING: 'CREDIT UNDERWRITING',
  DROPPED: 'DROPPED',
  EOI_RECEIVED: 'EOI RECEIVED',
  EOI_SUBMITTED: 'EOI SUBMITTED',
  TENDER_SOURCED: 'TENDER SOURCED',
  PENDING_WITH_ESTIMATION: 'PENDING WITH ESTIMATION',
  PENDING_WITH_TENDER_TEAM: 'PENDING WITH TENDER TEAM',
  PENDING: 'PENDING',
  TENDER_BID_OPENING_AND_UPDATES: 'TENDER BID OPENING AND UPDATES',
  FINANCIAL_BID_OPENING_AND_UPDATES: 'FINANCIAL BID OPENING AND UPDATES',
  RE_TENDERED: 'RE-TENDERED',
  CANCELLED: 'CANCELLED',
  TENDER_RECEIVED: 'TENDER RECEIVED',
  TECHNICAL_BID_OPENING_AND_UPDATES: 'TECHNICAL BID OPENING AND UPDATES',
  COMMERCIAL_BID_OPENING_AND_UPDATES: 'COMMERCIAL BID OPENING AND UPDATES',
  FINANCIAL_COMMERCIAL_BID_OPENING_AND_UPDATES:
    'FINANCIAL/COMMERCIAL BID OPENING AND UPDATES',
  REVERSE_AUCTION: 'REVERSE AUCTION',
  CHECKED: 'CHECKED',
  SUPPLIER_ASSIGNED: 'SUPPLIER ASSIGNED',
  SUPPLIER_QUERY: 'SUPPLIER Query',
  SUPPLIER_NOTIFIED: 'SUPPLIER NOTIFIED',
  SUPPLIER_ACCEPTED: 'SUPPLIER ACCEPTED',
  LEAD_CONTRACTOR_IDENTIFIED: 'LEAD CONTRACTOR IDENTIFIED',
  MOU_SUBMITTED: 'MOU SUBMITTED',
  R_COM_APPROVAL_DONE: 'R COM APPROVAL DONE',
  M_COM_APPROVAL_DONE: 'M COM APPROVAL DONE',
  CHANGE_IN_TENDER_AWARDING_DATE: 'CHANGE IN TENDER AWARDING DATE',
  MANAGEMENT_APPROVAL: 'MANAGEMENT APPROVAL',
  ENQUIRY_ON_HOLD: 'Enquiry On Hold',
  OFFER_SUBMITTED: 'OFFER SUBMITTED',
  NDA_APPROVAL_PENDING: 'NDA APPROVAL PENDING',
  CREDIT_VERIFICATION_PENDING: 'CREDIT VERIFICATION PENDING',
  CREDIT_VERIFICATION_DONE: 'CREDIT VERIFICATION DONE',
  SUPPLIER_REGRETTED: 'SUPPLIER REGRETTED',
  INITIAL_COSTING: 'INITIAL COSTING',
  QUALITY_APPROVAL: 'QUALITY APPROVAL',
  SAMPLING: 'SAMPLING',
  FINAL_COSTING_NEGOTIATION: 'FINAL_COSTING_NEGOTIATION',
  ENQUIRY_GENERATED: 'ENQUIRY GENERATED',
  REQUIREMENT_GENERATED: 'REQUIREMENT GENERATED',
  SAMPLE_APPROVAL_CYCLE: 'SAMPLE_APPROVAL CYCLE',
  SAMPLE_APPROVED: 'SAMPLE APPROVED',
  REQUIREMENTS_RECEIVED: 'Requirements Received',
  CONVERTED_TO_ENQUIRY: 'Converted to Enquiry',
  PARTIALLY_WON: 'Partially Won',
  CLOSED: 'Closed',
  ASSIGNED_TO_CMU: 'ASSIGNED TO CMU',
  ACCEPTED_BY_CMU: 'ACCEPTED BY CMU',
  REJECTED_BY_CMU: 'REJECTED BY CMU',
  ESTIMATION_STARTED: 'ESTIMATION STARTED',
  SUBMITTED_FOR_APPROVAL: 'SUBMITTED FOR APPROVAL',
  PROPOSAL_SUBMITTED: 'PROPOSAL SUBMITTED',
  RE_TENDERING: 'RE_TENDERING',
  EARLY_ENQUIRY: 'EARLY ENQUIRY',
  WAITING_FOR_PO: 'WAITING FOR PO',
  REJECTED_BY_RISK_TEAM: 'REJECTED BY RISK TEAM',
  REJECTED_BY_INTERNAL_TEAM: 'REJECTED BY INTERNAL TEAM',
  IN_DISCUSSION: 'IN DISCUSSION',
  OFFER_ACCEPTED: 'OFFER ACCEPTED',
  REOPEN: 'REOPEN',
  RFQ_RELEASED: 'RFQ RELEASED',
  ASSIGNED_TO_BU: 'ASSIGNED TO BU',
  'EOI/BQ_SUBMITTED': 'EOI/BQ SUBMITTED',
  BID_SUBMITTED: 'BID SUBMITTED',
  BID_RESULTS_DECLARED: 'BID RESULTS DECLARED',
  STRATEGIC_PARTNERSHIP: 'STRATEGIC PARTNERSHIP',
  QUERY_RECEIVED: 'QUERY RECEIVED',
  ENQUIRY_RECEIVED: 'ENQUIRY RECEIVED',
  DESIGN_AND_DEVELOPMENT: 'DESIGN_AND_DEVELOPMENT',
  UNDER_TECHNICAL_BID_EVALUATION: 'UNDER TECHNICAL BID EVALUATION',
  ORDER_CANCELLED: 'ORDER CANCELLED',
  PENDING_WITH_ESTIMATIONS: 'PENDING_WITH_ESTIMATIONS',
  PENDING_WITH_BUYER: 'PENDING_WITH_BUYER',
  QUOTE_RECEIVED: 'QUOTE_RECEIVED',
  REQUOTE: 'REQUOTE',
  PENDING_CREDIT_EVALUATION: 'PENDING CREDIT EVALUATION',
  HOT: 'HOT',
  COLD: 'COLD',
  PARTIAL_OFFER_GENERATED: 'PARTIAL OFFER GENERATED',
  FORECAST_TO_CLOSE: 'FORECAST TO CLOSE',
  OFFER_PREPARATION_IP: 'OFFER PREPARATION IP',
  OFFER_SUBMITTED_TO_CUSTOMER: 'OFFER SUBMITTED TO CUSTOMER',
  DISCUSSIONS: 'DISCUSSIONS',
  NO_RESPONSE: 'NO RESPONSE',
  INTERNAL_ESTIMATION: 'INTERNAL ESTIMATION',
  PRODUCTION_REQUOTE: 'PRODUCTION REQUOTE'
};

export const DEFAULT_STATUS_LIST = [
  'WON',
  'LOST',
  'REGRET',
  'HOLD',
  'PENDING_CLARIFICATION',
];

export const STATUS_WITH_REASON_HEADER = [
  'WON',
  'LOST',
  'REGRET',
  'HOLD',
  'PENDING_CLARIFICATION',
  'DROPPED',
  'PO_RECEIVED',
];

export const CONFIDENCE = {
  HIGH: 'rgb(35, 139, 63)',
  LOW: 'rgb(201, 0, 0)',
  MEDIUM: 'rgb(230, 134, 0)',
};
