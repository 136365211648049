import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreditUnderWritingService } from 'src/app/services/http/credit-underwriting.service';
import { S3Service } from 'src/app/services/http/s3.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-credit-underwriting',
  templateUrl: './credit-underwriting.component.html',
  styleUrls: ['./credit-underwriting.component.scss']
})
export class CreditUnderwritingComponent implements OnInit {

    acceptedFileType = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    acceptedAttachmentFileType = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .zip, .rar, .rev, .r00, .r01, .7z';
    creditUnderwritingForm: FormGroup;
    description: String = 'This is a test Description to test the description feature. Here we can write stuff and it will display in the ui. The quick fox jumps over the lazy dog'    

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private s3Service: S3Service,
    private _fb: FormBuilder,
    private creditUnderwritingService: CreditUnderWritingService,
    private _notification: NotificationService
    ) { }


  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.creditUnderwritingForm = this._fb.group({
      excel: this._fb.group({
        uploaded: [false],
        errors: [true],
        name: [null, Validators.compose([Validators.required])],
        size: [null],
        type: [null],
        _id: [null, Validators.compose([Validators.required])],
      }),
      attachment: this._fb.group({
        uploaded: [false],
        uploadInProgress: [false],
        uploadProgress: [0],
        name: [null, Validators.compose([Validators.required])],
        size: [null],
        type: [null],
        _id: [null, Validators.compose([Validators.required])],
      }),
    });
  }

  uploadFile($event, filetype) {
     const file = $event.target.files[0];
     if (filetype === 'excel') {
       let excelForm = this.creditUnderwritingForm.controls.excel;
       excelForm['controls'].uploaded.setValue(false);
       excelForm.patchValue({name: file.name, size: file.size, type: file.type});
       this.s3Service.initS3SingleUpload(file).subscribe((response) => {
         if (response && response._id) {
          excelForm['controls'].uploaded.setValue(true);
          excelForm['controls']._id.setValue(response._id);
          this.verifyExcel();
         }
       },
       err => {
         this.changeFile('excel');
         this.clearInput('inputExcel-edited');
       });
     } else {
      let attachmentForm = this.creditUnderwritingForm.controls.attachment;
      attachmentForm['controls'].uploaded.setValue(false);
      attachmentForm.patchValue({name: file.name, size: file.size, type: file.type});
      let {progressState, responseObservable} = this.s3Service.initS3SingleUploadWithProgress(file);
      responseObservable.subscribe((response) => {
        if (response && response._id) {
         attachmentForm['controls'].uploaded.setValue(true);
         attachmentForm['controls']._id.setValue(response._id);
         attachmentForm['controls'].uploadInProgress.setValue(false);

        }
      },
      err => {
        this.changeFile('file');
        this.clearInput('inputFile-edited');
      });
      progressState.subscribe((progress)=> {
        attachmentForm['controls'].uploadInProgress.setValue(true);
        attachmentForm['controls'].uploadProgress.setValue(progress.progress);
      },
      err => {
        this.changeFile('file');
        this.clearInput('inputFile-edited');
      })
     }
  }
  verifyExcel() {
    let excelForm = this.creditUnderwritingForm.controls.excel;
    this.creditUnderwritingService.verifyExcel({
      fileId: this.creditUnderwritingForm.controls.excel['controls']._id.value
    }).subscribe(response => {
      if(response && response.data.length>0){
        excelForm.setErrors({...excelForm.errors, validationError: response.data });
      } else {
        let existingErrors = excelForm.errors;
        if (existingErrors && existingErrors.validationError) {
          delete existingErrors.validationError;
        }
        excelForm.setErrors({...existingErrors && existingErrors });
      }
      console.log(response);
    })
  }
  changeFile(filetype) {
     if(filetype=='excel'){
       this.creditUnderwritingForm.controls.excel.reset();
     }else {
       this.creditUnderwritingForm.controls.attachment.reset();
     }
  }

  submitForm() {
    if(!this.creditUnderwritingForm.controls.excel['controls'].uploaded.value){
      this._notification.errorMessage({ message: "Please upload an excel first" });
    }else if(this.creditUnderwritingForm.controls.excel.errors && this.creditUnderwritingForm.controls.excel.errors.validationError && this.creditUnderwritingForm.controls.excel.errors.validationError.length){
      this._notification.errorMessage({ message: `Please fix the errors in the excel & re-upload the file` });
    }else if(!this.creditUnderwritingForm.controls.attachment['controls'].uploaded.value){
      this._notification.errorMessage({ message: "Please upload attachments" });
      return;
    }
    
    if (this.creditUnderwritingForm.controls.excel['controls'].uploaded.value && !this.creditUnderwritingForm.controls.excel.errors.validationError) {
      this.creditUnderwritingService
        .createUnderwriting({
          excelId:
            this.creditUnderwritingForm.controls.excel['controls']._id.value,
          attchmentFileId : 
            this.creditUnderwritingForm.controls.attachment['controls']._id.value
        })
        .subscribe((result) => {
          console.log(result);
          if (result.success) {
            this.creditUnderwritingForm.reset();
            this._notification.successMessage({ message: "File(s) uploaded successfully" });
          } else {
             this._notification.errorMessage({ message: 'Something went wrong. Please try again' });
          }
        });
    }
  }

  clearInput(id) {
    const element = (<HTMLInputElement>document.getElementById(id));
    element.value = '';
    element.value = null;
  }

}
