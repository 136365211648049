<!-- Layout Container -->
<div class="layout-container">
  <!-- Navigation Section -->
  <div class="navigation-section">
    <app-side-navigation></app-side-navigation>
  </div>
  <!-- ./Navigation Section -->
  <!-- Content Section -->
  <div class="content-section">
    <!-- Pass all routes to the child components -->
    <router-outlet></router-outlet>
  </div>
  <!-- ./Content Section -->
</div>
<!-- ./Layout Container -->
