import { Component, Input, OnInit } from '@angular/core';
import { STATUS_MAPPER, STATUS, CONFIDENCE } from '../../../constants/reasonStatus';
import { TAGS } from '../../../constants/tags';
import { ACTIONS } from '../../../constants/auditlogActions';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notifications:any = {};
  showNotificationList = false;
  constructor() { }

  ngOnInit(): void {
  }

  onNotificationIconClick(){
    this.showNotificationList = !this.showNotificationList;
  }

  getEntity(entity){
    if(entity){
      return ACTIONS[entity];
    }
    return '' ;
  }

  getStatus(statuskey,actionType){
    if(actionType === 'EDIT_STATUS') return STATUS_MAPPER[statuskey];
    if(actionType === 'EDIT_CONFIDENCE') return statuskey;
    if(actionType === 'CREATE') return 'Enquiry Created';
    return '';
  }

  getStyles(actionType,value){
    if(actionType === 'EDIT_STATUS' || actionType === 'CREATE') return STATUS[value]?.color;
    if(actionType === 'EDIT_CONFIDENCE') return CONFIDENCE[value];
  }

  generateNotificationItem(item,index){
    let sentence = '';
    if(item.actionType === 'EDIT_STATUS' || item.actionType === 'EDIT_CONFIDENCE'){
      sentence = `${this.getEntity(item.actionType)} changed from `;
      sentence += `<span style="color:${this.getStyles(item.actionType,item.previousValue)};text-transform:capitalize;"> ${this.getStatus(item.previousValue,item.actionType).toLowerCase()}</span>`;
      sentence += ` to <span style="color:${this.getStyles(item.actionType,item.newValue)};text-transform:capitalize;"> ${this.getStatus(item.newValue,item.actionType).toLowerCase()}</span>`;
    }

    if(item.actionType === 'EDIT_TAG' || item.actionType === 'EDIT_DETAILS' || item.actionType === 'EDIT_RFQ_STATUS'){
      sentence = `<span>${this.getEntity(item.actionType)} ${item.changeType}</span>`;
    }

    if(item.actionType === 'ADD_REMARK'){
      sentence = '<span>Remark Added</span>';
    }

    if(item.actionType === 'CREATE'){
      sentence = `<span style="color:${this.getStyles(item.actionType,'ENQUIRY_CREATED')};text-transform:capitalize;">${this.getStatus('ENQUIRY_CREATED',item.actionType).toLowerCase()}</span>`;
    }

    document.querySelector(`#notification-item-${index}`).innerHTML = sentence;
  }

}
