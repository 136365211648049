import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { LayoutComponent } from './components/shared/layout/layout.component';
import { LoginComponent } from './components/general/login/login.component';
import { DemoComponent } from './components/general/demo/demo.component';
import { EnquiryComponent } from './components/general/enquiry/enquiry.component';
import { DummyComponent } from './components/general/dummy/dummy.component';
import { CreditUnderwritingComponent } from './components/credit-underwriting/credit-underwriting.component';
import { InstantQuoteComponent } from './components/instant-quote/instant-quote.component';
import { WorkspacesComponent } from './components/general/workspaces/workspaces.component';
import { ThreedViewerComponent } from './components/threed-viewer/threed-viewer.component';
import { RoleGuard } from './guards/role.guard';
import { DocumentRepositoryComponent } from './components/document-repository/document-repository.component';
import { QueryComponent } from './components/query/query.component';
import { GenericRfqComponent } from './components/generic-rfq/generic-rfq.component';
import { DataTeamHubComponent } from './components/data-team-hub/data-team-hub.component';
import { OtpLoginComponent } from './components/general/otp-login/otp-login.component';

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'otp-login',
    component: OtpLoginComponent,
  },
  {
    path: '',
    redirectTo: 'enquiry/list',
    pathMatch: 'full',
  },
  {
    path: 'dummy-route',
    component: DummyComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'demo',
        component: DemoComponent,
        loadChildren: () =>
          import('./components/general/demo/demo.module').then(
            (m) => m.DemoModule
          ),
      },
      {
        path: 'enquiry',
        component: EnquiryComponent,
        loadChildren: () =>
          import('./components/general/enquiry/enquiry.module').then(
            (m) => m.EnquiryModule
          ),
      },
      {
        path: 'credit-underwriting',
        component: CreditUnderwritingComponent,
        loadChildren: () =>
          import(
            './components/credit-underwriting/credit-underwriting.module'
          ).then((m) => m.CreditUnderwritingModule),
      },
      {
        path: 'document-repository',
        component: DocumentRepositoryComponent,
        loadChildren: () =>
          import(
            './components/document-repository/document-repository.module'
          ).then((m) => m.DocumentRepositoryModule),
      },
      {
        path: 'workspaces',
        component: WorkspacesComponent,
        loadChildren: () =>
          import('./components/general/workspaces/workspaces.module').then(
            (m) => m.WorkspacesModule
          ),
      },
      {
        path: 'clarifications',
        component: QueryComponent,
        loadChildren: () =>
          import(
            './components/query/query.module'
          ).then((m) => m.QueryModule),
      },

      {
        path: 'rfqs',
        component: GenericRfqComponent,
        loadChildren: () =>
          import(
            './components/generic-rfq/generic-rfq.module'
          ).then((m) => m.GenericRfqModule),
      },
      {
        path: 'instant-quote',
        component: InstantQuoteComponent,
        loadChildren: () =>
          import('./components/instant-quote/instant-quote.module').then(
            (m) => m.InstantQuoteModule
          ),
      },
      {
        path: '3d-viewer',
        component: ThreedViewerComponent,
        loadChildren: () =>
          import('./components/threed-viewer/threed-viewer.module').then(
            (m) => m.ThreedViewerModule
          ),
      },
      {
        path: 'template',
        canActivate: [RoleGuard],
        component: EnquiryComponent,
        loadChildren: () =>
          import('./components/general/template/template.module').then(
            (m) => m.TemplateModule
          ),
          data: {
            authorizedRoles: ['ADMIN', 'EQS_ADMIN']
          }
      },
      {
        path: 'data-team-hub',
        component: DataTeamHubComponent,
        loadChildren: () =>
          import('./components/data-team-hub/data-team-hub.module').then(
            (m) => m.DataTeamHubModule
          ),
      },
    ],
  },
];
