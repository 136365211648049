<div class="notification-container" (click)="onNotificationIconClick()">
    <div class="notification-icon-container">
        <img class="notification-icon" src="/assets/images/Bell.svg" />
        <div class="notification-count" *ngIf="notifications.newNotifications && notifications.newNotificationCount>0">
            <span class="count-txt">{{notifications.newNotificationCount}}</span>
        </div>
    </div>
    <div class="notification-list" *ngIf="showNotificationList">
        <div class="notification-list-header">Notifications</div>
        <div class="notification-list-refresh"
            *ngIf="notifications.newNotifications && notifications.newNotificationCount>0">
            <div class="notification-list-refresh-txt">Refresh the page to see the updated details</div>
        </div>
        <div *ngFor="let item of notifications.messages;let i = index">
            <div class="notification-list-item">
                <div class="job-details">
                    <div class="job-number">{{item.jobID}}</div>
                    <div class="job-update-details">
                        <div id="{{ 'notification-item-' + i }}">
                            {{generateNotificationItem(item,i)}}
                        </div>
                        <div>
                            <span class="job-updated-by">By {{item.actionBy}} </span>
                            <span class="job-updated-on">On {{item.actionDate}} | {{item.actionTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>