import { Injectable } from '@angular/core';
import { App, LoginService } from '@zetwerk/zetui';
import { of } from 'rxjs';

const ALLOWED_DOMAINS = ['unimacts.com'];

@Injectable({
  providedIn: 'root',
})
export class EQSLoginService extends LoginService {
  checkEmailRegistered(email: string) {
    const [, domain] = email.split('@');
    if (!ALLOWED_DOMAINS.includes(domain)) {
      return of(<any>{ success: false });
    }
    return super.checkEmailRegistered(email);
  }
}
