import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

const EQS_END_POINT = environment.apiEndPoint;
interface ProgressState {
  progress: number;
  totalSize: string;
  totalUploaded: string;
}

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  slug: any;

  constructor(
    private http: HttpClient,
  ) { }

  initS3SingleUpload(file, options?) {
    let s3SignedConfig;
    return this.getSignedUploadUrl(file).pipe(
      mergeMap(uploadConfig => {
        s3SignedConfig = uploadConfig;
        s3SignedConfig.type = file.type;
        return this.uploadFileToS3(file, uploadConfig, true);
      }),
      filter(event => event instanceof HttpResponse),
      mergeMap((event: HttpResponse<any>) => this.createFileObjectForS3Upload(s3SignedConfig, options)),
      map((res: any) => res.data),
      catchError(err => this.fileUploadFailed(err))
    );
  }
  initS3SingleUploadWithProgress(file, options?) {
    let s3SignedConfig;
    let progressSubject = new BehaviorSubject<ProgressState>({
      progress: 0,
      totalSize: '0 MB',
      totalUploaded: '0 MB',
    });
    let progressState = progressSubject.asObservable();
    return {progressState, responseObservable: this.getSignedUploadUrl(file).pipe(
      mergeMap(uploadConfig => {
        s3SignedConfig = uploadConfig;
        s3SignedConfig.type = file.type;
        return this.uploadFileToS3(file, uploadConfig, true);
      }),
      tap((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const total = this.formatBytes(event.total, 2);
          const loaded = this.formatBytes(event.loaded, 2);
          const progress = Math.round((100 * event.loaded) / event.total);
          progressSubject.next(<ProgressState>{
            progress: progress,
            totalSize: total,
            totalUploaded: loaded
          });
        }
      }),
      filter(event => event instanceof HttpResponse),
      mergeMap((event: HttpResponse<any>) => this.createFileObjectForS3Upload(s3SignedConfig, options)),
      map((res: any) => res.data),
      catchError(err => this.fileUploadFailed(err))
    )};
  }
  formatBytes = (a, b): string => {
    if (0 === a) {
      return '0 Bytes';
    }
    // using the decimal convention
    const c = 1000,
      d = b || 2,
      e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  };
  getSignedUploadUrl(file: File) {
    const fileName = encodeURIComponent(file.name);
    return this.http.post(`${EQS_END_POINT}files/signed-upload-urls?fileNames=${fileName}`, {}).pipe(
      map((res: any) => res.data[0]),
    );
  }


  fileUploadFailed(error) {
    return throwError(error);
  }
  createFileObjectForS3Upload(s3SignedConfig, options?) {
    let paramString = '';

    if (options?.queryParams) {
      paramString = Object.entries(options?.queryParams)
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join('&');
    }
    console.log(s3SignedConfig, paramString);
    if (!s3SignedConfig.type) {
      const name = s3SignedConfig.name.split('.');
      s3SignedConfig.type = name[name.length - 1];
    }

    return this.http.post(`${EQS_END_POINT}files/upload-files`, s3SignedConfig);
  }

  uploadFileToS3(file: File, s3SignedConfig, progress: boolean = false) {
    const headers = new HttpHeaders()
      .set('Content-Type', file.type)
      .set('x-amz-acl', 'public-read')
      .set('Content-Disposition', 'attachment');
    let init = {
      ...(progress && { reportProgress: true }),
      headers
    };
    const req = new HttpRequest('PUT', s3SignedConfig.signedUrl, file, init);
    return this.http.request(req).pipe(
      filter(event => event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response ),
      mergeMap(event => {
        if (event.type === HttpEventType.UploadProgress && progress) {
          return of(event);
        }

        if (event.type === HttpEventType.Response) {
          return of(event);
        }
      }),
      catchError(err => {
        const errorFile = Object.assign(this.copyFile(file), err);
        errorFile.error = true;
        return throwError(errorFile);
      })
    );
  }
  copyFile(file) {
    let newFile = {};
    newFile['lastModified'] = file.lastModified;
    newFile['lastModifiedDate'] = file.lastModifiedDate;
    newFile['name'] = file.name;
    newFile['size'] = file.size;
    newFile['type'] = file.type;
    newFile['webkitRelativePath'] = file.webkitRelativePath;
    return newFile;
  }
}
