import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { RFQ_STATUS_DROPDOWN_LIST } from "src/app/constants/rfqStatusTypes";
@Component({
  selector: 'app-query-status-cell',
  templateUrl: './query-status-cell.component.html',
  styleUrls: ['./query-status-cell.component.scss'],
})
export class QueryStatusCellComponent implements ICellCmp {
  params;
  columnDef;
  element;
  public statusMapper = RFQ_STATUS_DROPDOWN_LIST;
  constructor() {}

  init(params: any) {}
}
