import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader/loader.service';
import { OmsService } from '../services/http/oms.service';
import { NotificationService } from '../services/notification/notification.service';

const loaderExclusionList = [
  'task-status'
]
const otpErrorMessage = 'The OTP entered is incorrect.';


@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService, private oms: OmsService, private _notification: NotificationService) {}

  activeRequests = 0;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');
    if (this.activeRequests === 0 && !this.hasMatchingPatternFound(loaderExclusionList, request.url)) {
      this.showLoader();
    }
    this.activeRequests++;
    if (token) {
      request = request.clone({
        headers: request.headers.set('authorizationtoken', token),
      });
    }
    const originSystem = window.location.href.includes('workspaces') ? 'workspaces' : 'eqs'
    request = request.clone({
        headers: request.headers.set('originsystem', originSystem),
    });
    if (!request.headers.has('Content-Type')   &&
      !request.url.endsWith(`/api/v1/file`) && !request.url.includes(`scopeMatrix`) && !request.url.includes(`excelUpload`) && !request.url.includes(`rfqBulkCreate`) && !request.url.includes(`bulk-upload-excel`) && !request.url.includes(`generic-bulk-create-enquiry`) ) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    const slug = this.oms.getCompanySlug();
    if (request.url.includes('miscellaneous-details') && slug) {
      request = request.clone({
        headers: request.headers.set('X-COMPANY-SLUG', slug)
      });
    }
    // TODO: Fix 431 error
    // const cookieHeaderConfigs = {
    //   withCredentials: request.url.includes('.zetwerk.com')
    // };
    // request = request.clone({
    //   ...cookieHeaderConfigs
    // });
    return next.handle(request).pipe(
      // show the loader here
      tap(res => {
        // console.log(res);
      }),
      catchError((error: HttpErrorResponse) => {
        this.hideLoader();
        if (error.status === 401 && error?.error?.message === otpErrorMessage) {
          this._notification.errorMessage({ message: otpErrorMessage });
        } else if (error.status === 401) {
          const errorMessage = 'You are either unauthorised to access EQS or your user session has expired. Please try logging in again. If the problem persists, contact smt-team@zetwerk.com';
          this._notification.errorMessage({ message: errorMessage });
          setTimeout(() =>{ this.logout() }, 3000);
        } else if (error.status === 0) {
           /** Pre condition failed - WAF */
          this._notification.errorMessage({ message: 'Please remove any URL/ Website links from General scope text box and try again.'});
        } else {
          this._notification.errorMessage({ message: `Status: ${error.statusText}(${error.status})\nMessage: ${error.message}` });
        }
        return throwError(error);
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.hideLoader();
        }
      })
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
  private hasMatchingPatternFound (bypassPathList, absoluteRequestPath): any {
    let matchFound = false;
    bypassPathList.forEach(path => {
      if (absoluteRequestPath.includes(path)) {
        matchFound = true;
      }
    });
    return matchFound;
  };

  private logout() {
    window.location.reload();
    localStorage.clear();
  }
}
