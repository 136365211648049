export const ACTIONS = {
  CREATE: 'Created',
  EDIT_TAG: 'Tag',
  EDIT_STATUS: 'Status',
  EDIT_CONFIDENCE: 'Confidence Level',
  EDIT_BASIC: 'Basic',
  EDIT_REQUIREMENTS: 'Requirement',
  EDIT_PROPOSAL: 'Proposal',
  EDIT_OFFER: 'Offer',
  EDIT_SUBMISSIONS: 'Submissions',
  EDIT_COMPETITION: 'Competition',
  EDIT_BIDCRITERIA: 'Bid Criteria',
  EDIT_RFQ: 'RFQ',
  MIGRATED: 'Migrated',
  ADD_REMARK: 'Remark',
  EDIT_DETAILS: 'Details',
  SAMPLE_EDIT_STATUS: 'Status',
  EDIT_TERMSANDCONDITIONS: 'Terms & Conditions',
  EDIT_SUPPLYCRITERIA: 'Supply Criteria',
  BULK_UPDATE: 'Bulk Update',
  EDIT_COSTDETAILS: 'Cost Details',
  EDIT_RFQ_STATUS: 'RFQ',
  EDIT_PODETAILS: 'PO Details',
  EDIT_COMMUNICATION: 'Communication',
  EDIT_COMPETITORS: 'Competitors',
  EDIT_BIDRESULTS: 'Bid Results',
  EDIT_QUERY: 'Query',
  EDIT_QUERY_STATUS: 'Edit Query Status',
  DELETE_QUERY: 'Delete Query',
  CREATE_QUERY: 'Create Query',
  QUERY: 'Query',
  EDIT_TENDERONHOLD: 'Edit Tender On Hold',
  EDIT_TENDERLIST: 'Edit Tender List',
  EDIT_BIDRESULT: 'Edit Bid Result',
  RFQ_DRAFT: 'RFQ Draft',
  RFQ_CREATED: 'RFQ Created',
  RFQ_SENT: 'RFQ Sent',
  RFQ_UPDATED: 'RFQ Updated',
  RFQ_ACCEPTED: 'RFQ Accepted',
  RFQ_REJECTED: 'RFQ Rejected',
  DELETE: 'Deleted'
};

export const RFQ_ACTIONS = {
  DRAFT: 'RFQ Drafted',
  RFQ_SENT: 'RFQ sent to supplier',
  AWAITING_RESPONSE: 'Waiting for Supplier to respond...',
  RFQ_OPENED: 'RFQ Opened',
  DRAWINGS_VIEWED: 'Drawings Viewed',
  SUPPLIER_NOTIFIED: 'Supplier Notified',
  RFQ_CANCELLED: 'RFQ Cancelled',
  SUPPLIER_RESPONDED: 'Supplier Responded',
  QUOTE_ACCEPTED: 'Quote Accepted',
  SUPPLIER_REGRET: 'Supplier Regret',
  QUOTE_REJECTED: 'Quote Rejected',
  PARTIALLY_QUOTED: 'RFQ Partially Quoted'
}

export const SUB_ACTIONS = {
  ADDED: 'Added',
  UPDATED: 'Updated',
  REMOVED: 'Removed',
  GENERATED: 'Generated',
  REVISION: 'Revision Request',
  RFQ_DRAFT: 'Drafted',
  RFQ_CREATED: 'Created',
  RFQ_SENT: 'Sent to Supplier',
  RFQ_UPDATED: 'Updated',
  RFQ_ACCEPTED: 'Accepted',
  RFQ_REJECTED: 'Rejected'
}
