import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessagingService } from '../../../services/notification/messaging.service';
import { WorkspaceService } from 'src/app/services/http/workspace.service';
@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss'],
})
export class WorkspacesComponent implements OnInit {
  // Message recevied from FCM
  messages: any = [];
  messageObj = { messages : [], newNotifications: false, newNotificationCount:0};
  toolTipMessage = 'Please select at least one enquiry that is not in Supplier Query or Pending Clarification Status';
  constructor(
    public messagingService: MessagingService,
    private cd: ChangeDetectorRef,
    private _workspaceService: WorkspaceService
  ) {}

  async ngOnInit() {
    this.messagingService.requestPermission();

    navigator.serviceWorker.addEventListener('message', (event) => {
      this.messages.unshift(event.data.data);
      this.messageObj = {
        messages:this.messages,
        newNotifications:true,
        newNotificationCount:this.messageObj.newNotificationCount + 1
      }
    });

    this.messagingService
      .getLatestAuditLogs({ templateId: 'TEMPLATE-0000028' })
      .subscribe((response) => {
        this.messages.unshift(...response.data);
        this.messageObj = {
          messages:this.messages,
          newNotifications:false,
          newNotificationCount:0
        }
      });
  }

  bulkActionsList = ['Update Action', 'Update Status']

  selectedAction(action) {
    this.onBulkActions(action)
  }

  get allowBulkActions() {
    return this._workspaceService.allowBulkActions;
  }

  get allowBulkStatus() {
    return this._workspaceService.allowBulkStatus;
  }

  get isBulkSelect() {
    return this._workspaceService.isBulkSelect;
  }

  get showDropDown() {
    return this._workspaceService.showDropDown;
  }

  onBulkSelect() {
    this._workspaceService.isBulkSelect = !this._workspaceService.isBulkSelect;
    this._workspaceService.changeBulkSelect(this.isBulkSelect);
  }

  toggleDropDown() {
    this._workspaceService.showDropDown = !this._workspaceService.showDropDown;
  }

  onBulkActions(action) {
    this._workspaceService.changeBulkAction(true, action);
  }

}
