<div class="page-container">
    <div class="page-header">
        <div class="left">
            <div class="title">Prototype Workspace</div>
        </div>
        <div class="center">
            <div class="background-messages-notification" *ngIf="messageObj.newNotifications">
                <div class="background-messages-notification-txt">There have been some changes in the page.</div>
                <div class="background-messages-notification-txt">Refresh the page to see the updated details.</div>
            </div>
        </div>
        <div class="right">
          <app-notification class="notification-center" [notifications]='messageObj'></app-notification>
          <button
            class="button button-plain select-enquiries"
            (click)="onBulkSelect()"
            *ngIf="!isBulkSelect"
          >
            <img src="/assets/images/list-unselect.svg" alt="filter" />
            Select Enquiries
          </button>
    
    
          <div class="bulk-actions-container" dropdown *ngIf="isBulkSelect">
            <div class="dropdown-container" (click)="toggleDropDown()">
                <div class="add-more">
                    <div>
                        <span>
                            Bulk Actions
                        </span>
                    </div>
                </div>
                <div class="arrow">
                    <i class="fa fa-sort-up" *ngIf="showDropDown" style="
                    transform: translateY(10px) translateX(6px);
                    color: rgb(255,255,255);
                  "></i>
                    <i class="fa fa-sort-down" *ngIf="!showDropDown" style="transform: translateY(5px) translateX(6px)"></i>
                </div>
            </div>
          </div>
          <div class="bulk-actions-dropdown" *ngIf="showDropDown && isBulkSelect">
            <div class="dropdown-title">
                <span>Perform Actions</span>
            </div>
            <div class="menuitem" *ngFor="let bulkActionItem of bulkActionsList"         
              [tooltip]="!allowBulkActions && bulkActionItem === 'Update Action' ? toolTipMessage : ''"
              [ngClass]="(bulkActionItem === 'Update Action' ? !allowBulkActions : !allowBulkStatus) ? 'disabled' : ''">
                <div
                    (click)="selectedAction(bulkActionItem)">
                    <span class="bulkAction-chip">
                        {{ bulkActionItem }}
                    </span>
                </div>
            </div>
          </div>
    
    
          <button
            class="button button-plain cancel-select-enquiries"
            (click)="onBulkSelect()"
            *ngIf="isBulkSelect"
          >
            <i class="fa fa-times-circle"></i>
            Cancel
          </button>
        </div>
    </div>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
</div>
