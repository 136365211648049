import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FILE_UPLOAD_CONFIG, IFileUploadConfig, UploadFileType } from '@zetwerk/zetui';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { roundOffValue } from 'src/app/utils/helper';
import { environment } from 'src/environments/environment';

const API_ENDPOINT = environment.apiEndPoint;

//TODO: Remove once ZETUI accepts all these config as Input.
const fileUploadConfig: IFileUploadConfig = {
  acceptedFileTypes: ['step', 'stp', 'iges', 'igs', 'stl', 'obj'],
  maxFiles: 1,
  maxSize: '50mb',
  signedUrlEndpoint: `${API_ENDPOINT}/files/signed-upload-urls`,
  registerFileEndpoint: `${API_ENDPOINT}/files?isProgressiveRequired=false&isThumbnailRequired=true&is3DFileUpload=true&isPdfThumbnailRequired=false`
};

@Component({
  selector: 'app-threed-viewer',
  templateUrl: './threed-viewer.component.html',
  styleUrls: ['./threed-viewer.component.scss'],
  providers: [
    { 
      provide: FILE_UPLOAD_CONFIG, 
      useValue: fileUploadConfig 
    }
  ]
})
export class ThreedViewerComponent implements OnInit {
  viewerForm: FormGroup;
  files: any[] = [];
  type: UploadFileType = UploadFileType.SINGLE_UPLOAD;
  context = { uploadedFrom: '3D_VIEWER' }

  constructor(private _fb: FormBuilder, private _notificationService: NotificationService) { }

  ngOnInit(): void {
    this.viewerForm = this._fb.group({
      length: this._fb.group({
        unit: ['mm'],
        value: [''],
      }),
      breadth: this._fb.group({
        unit: ['mm'],
        value: [''],
      }),
      height: this._fb.group({
        unit: ['mm'],
        value: [''],
      }),
      surfaceArea: this._fb.group({
        unit: ['mm'],
        value: [''],
      }),
      volume: this._fb.group({
        unit: ['mm'],
        value: [''],
      }),
    })
  }

  onFileUploadComplete(files: any[]): void {
    const file = files[0];
    if (!['step', 'stp', 'igs', 'iges', 'stl', 'obj'].includes(file.name.split('.').pop().toLowerCase())) {
      this._notificationService.errorMessage({ message: 'Invalid file format!' })
    } else {
      this.files = [
        {
          src: file.stl?.src ? file.stl.src : file.src,
          name: file.stl?.name ? file.stl.name : file.name,
          selected: true
        }
      ];
    }
  }

  onFileLoaded(event: any) {
    if (event?.volume) {
      this.viewerForm.patchValue({
        length: { value: roundOffValue(event?.coordinates?.x, 2) },
        breadth: { value: roundOffValue(event?.coordinates?.y, 2) },
        height: { value: roundOffValue(event?.coordinates?.z, 2) },
        surfaceArea: { value: roundOffValue(event?.surfaceArea, 2) },
        volume: { value: roundOffValue(event?.volume, 2) },
      })
    }
  }

  onNewFileUploadClick() {
    this.files.length = 0;
  }
}
