export function convertType(value: any) {
  const v = Number(value);
  return !isNaN(v)
    ? v
    : value === 'undefined'
    ? undefined
    : value === 'null'
    ? null
    : value === 'true'
    ? true
    : value === 'false'
    ? false
    : value;
}

export function createLink(url) {
  const link = document.createElement('a');
  link.href = url;
  link.dispatchEvent(
    new MouseEvent(`click`, {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
}

export function roundOffValue(value, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export function getInitialRoutePathForEnquiry(templateKey) {
  switch (templateKey) {
    case 'xometryTemplate':
      return 'details';
    default:
      return 'basic';
  }
}

export function getPartsAndQuantity(enquiry) {
  let totalNumberOfParts = 0;
  let totalQuantity = 0;
  enquiry.details.groups.forEach((group) => {
    if (group.groupDetails.groupKey === 'partList') {
      totalNumberOfParts += group.items.length;
      group.items.forEach((item) => {
        item.fields.forEach((field) => {
          if (field.fieldKey == 'quantity') {
            totalQuantity += field.fieldValue;
          }
        });
      });
    }
  });

  enquiry.totalNumberOfParts = totalNumberOfParts;
  enquiry.totalQuantity = totalQuantity;

  return enquiry;
}

export function date_diff_indays(date1, date2) {
  let dt1 = new Date(date1);
  let dt2 = new Date(date2);
  return Math.abs(
    Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    )
  );
}


  /**
   * Convert Timestamp to date
   * @param timestamp
   */
export function getMoment(timestamp) {
    if (timestamp) {
      let timestampFormatted = new Date(timestamp);
      let today = new Date();
      /** Get difference in days */
      let result = String(date_diff_indays(timestampFormatted, today));

      if (result === '0') return 'Today';
      else if (timestampFormatted > today) {
        if (result === '1') result += ' day left';
        else result += ' days left';
      } else {
        if (result === '1') result += ' day ago';
        else result += ' days ago';
      }

      return result;
    } else {
      return '';
    }
  }
