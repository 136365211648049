<div>
    <div class="pr-basic-container fullscreen">
        <div class="title">
            Credit Underwriting
        </div>
        <div class="description" *ngIf="description">
            <p><a href="https://docs.google.com/spreadsheets/d/1epcVPUmqZZxpCLtF_mJEKyOcHwcVIClCfm0VQV5hylM/edit#gid=323981906" target="_blank">Excel File template</a> (Please use same formats)</p>
            <p>Attachments must be .zip/.rar/.7z format</p>
            <p>Uploaded Cases Dashboard</p>
        </div>
        <form [formGroup]="creditUnderwritingForm">
            <div class="file-upload container">
                <div class="row mb-4">
                    <label class="col-5 d-flex justify-content-end align-items-center eqs-required">Upload Excel </label>

                    <div class="buttons col-7 d-flex justify-content-center align-items-center"
                        *ngIf="!creditUnderwritingForm['controls'].excel['controls'].uploaded?.value">
                        <label for="inputExcel-edited" class="custom-file-upload">
                            <span class="eqs-button primary select-file">Select File</span>
                        </label>
                        <input type="file" accept="{{acceptedFileType}}" id="inputExcel-edited" style="display: none"
                            (input)="uploadFile($event,'excel')" />
                    </div>
                    <div class="uploaded-file-name col-7 d-flex justify-content-end align-items-center"
                        *ngIf="creditUnderwritingForm['controls']?.excel['controls'].uploaded?.value">
                        <span class="ellipsis"><img class="xls"
                                src="../../../../../assets/icons/files/xls.svg" style="margin-right: 4px;" />{{creditUnderwritingForm['controls'].excel['controls'].name?.value}}</span>
                        <label for="inputFile-change" class="ml10px">
                            <button class="eqs-button light change" (click)="changeFile('excel')">Change</button>
                        </label>
                        <input type="file" accept="{{acceptedFileType}}" id="inputExcel-change" style="display: none"
                            (change)="uploadFile($event,'excel')" />
                    </div>
                </div>
                <div class="row mb-4" *ngIf="creditUnderwritingForm['controls']?.excel.errors?.validationError?.length">
                    <div class="error-text"
                        *ngFor="let error of creditUnderwritingForm['controls']?.excel.errors?.validationError">
                        {{error}}
                    </div>
                </div>
            </div>

            <div class="file-upload container">
                <div class="row mb-4">
                    <label class="col-5 d-flex justify-content-end align-items-center eqs-required">Upload Attachments </label>
                    <div class="buttons col-7 d-flex justify-content-center align-items-center"
                        *ngIf="!creditUnderwritingForm['controls'].attachment['controls'].uploaded?.value">
                        <div class="mr-4" *ngIf="creditUnderwritingForm['controls'].attachment['controls'].uploadInProgress?.value">
                            {{creditUnderwritingForm['controls'].attachment['controls'].uploadProgress?.value}}
                            %</div>
                        <label id='file-label' for="inputFile-edited" class="custom-file-upload">
                            <span class="eqs-button primary select-file">Select File</span>
                        </label>
                        <input type="file" accept="{{acceptedAttachmentFileType}}" id="inputFile-edited"
                            style="display: none" (input)="uploadFile($event,'file')" />

                    </div>
                    <div class="uploaded-file-name col-7 d-flex justify-content-end align-items-center"
                        *ngIf="creditUnderwritingForm['controls'].attachment['controls'].uploaded?.value">
                        <span class="ellipsis"><img class="xls"
                                src="../../../../../assets/icons/files/file.png" style="margin-right: 4px;"/>{{creditUnderwritingForm['controls'].attachment['controls'].name?.value}}</span>
                        <label for="inputFile-change" class="ml10px">
                            <button class="eqs-button light change" (click)="changeFile('file')">Change</button>
                        </label>
                        <input type="file" accept="{{acceptedAttachmentFileType}}" id="inputFile-change"
                            style="display: none" (change)="uploadFile($event,'file')" />
                    </div>

                </div>

            </div>
            <div class="d-flex flex-row align-items-center justify-content-end">
                <button type="button" class="eqs-button light m10px"
                (click)="changeFile('excel');changeFile('attachment');">Reset</button>
            <button type="button" class="eqs-button primary m10px" (click)="submitForm()">Submit</button>
            </div>
        </form>
    </div>
</div>