import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { getMoment } from 'src/app/utils/helper';

@Component({
  selector: 'app-subtext-date-cell',
  templateUrl: './subtext-date-cell.component.html',
  styleUrls: ['./subtext-date-cell.component.scss']
})

export class SubtextDateCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {

  }

  getMoment(timestamp) {
    return getMoment(timestamp);
  }

}
