import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpService } from './http.service';

const API_ENDPOINT = environment.userApiEndPont;
const EQS_END_POINT = environment.apiEndPoint;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _httpService: HttpService) { }

  getUsersByNameAndRole(params) {
    return this._httpService
      .get({
        url: 'user/get-user-by-role',
        params,
        API_ENDPOINT: EQS_END_POINT,
      })
      .pipe(
        map((res: any) => {
          const userList = res.data &&  res.data.data ? res.data.data : [];
          return userList.map((x) => {
            x['name'] = `${x['firstName']} ${x['lastName']}`;
            if (x['email']) {
              let emailString = ` (${x['email']})`;
              x['name'] += emailString;
            }
            return { ...x };
          });
        })
      );
  }

  getUserDetailsById(userId) {
    return this._httpService
      .get({
        url: `user/${userId}`,
        API_ENDPOINT: EQS_END_POINT,
      })
      .pipe(
        map((res: any) => {
          res.data['name'] = `${res.data['firstName']} ${res.data['lastName']}`;
          if (res.data['email']) {
            let emailString = ` (${res.data['email']})`;
            res.data['name'] += emailString;
          }
          return { ...res.data };
        })
      );
  }

  getCompanyDetails(params) {
    return this._httpService.get({
      url: 'company',
      params,
      API_ENDPOINT,
    });
  }

  getBusinessUnits() {
    return this._httpService.get({
      url: 'business-unit',
      API_ENDPOINT
    });
  }

  /**
   * Saves the preference as a custom filter
   */
   getSavedFilterPrefrences() {
    return this._httpService.get({
      url: `user/get-user-filter-preferences`,
      API_ENDPOINT: EQS_END_POINT,
    });
  }

  /**
   * Saves the preference as a custom filter
   */
  saveFilterPrefrence(payload) {
    return this._httpService.post({
      url: `user/save-filter-preference`,
      payload,
      API_ENDPOINT: EQS_END_POINT,
    });
  }

  /**
   * Deletes the user's custom filter
   */
  deleteFilterPrefrence(filterName) {
    return this._httpService.delete({
      url: `user/delete-filter-preference/${filterName}`,
      payload: {},
      API_ENDPOINT: EQS_END_POINT,
    });
  }

  getUserRoles(): Array<string> {
    const user = JSON.parse(localStorage.getItem('user'));
    const roles = user?.roleDetails?.map((role: any) => role?.name);
    return roles;
  }

  getUserDetailsByEmail(email) {
    return this._httpService
      .get({
        url: `user/exists/${email}`,
        API_ENDPOINT: EQS_END_POINT,
      })
      .pipe(
        map((res: any) => {
          if(res?.data?.user) {
            const user = res?.data?.user;
            user.name = `${user?.firstName} ${user?.lastName}`;
            if (user.email) {
              let emailString = ` (${user?.email})`;
              user.name += emailString;
            }
            return user
          }
          return null;
        })
      );
  }
}
