import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  API_ENDPOINT = environment.apiEndPoint;

  constructor(private http: HttpClient) { }


  /**
   *
   * @param url
   * @param  it should be objects
   * example { totalPage: 0, skip: 10, limit: 10} etc
   * @return json
   */
  get({ url, params = {}, headers = {}, API_ENDPOINT = this.API_ENDPOINT }) {
    const constructedUrl = this.formUrlParam(url, params);
    // start loader
    return this.http.get(`${API_ENDPOINT}/${constructedUrl}`, { headers }).pipe(map((res) => {
      return this.handleResponse(res);
    }));
  }


  /**
   *
   * @param url
   * @param postBody
   * @param options
   * @return json
   */
  post({ url, payload, options = {}, API_ENDPOINT = this.API_ENDPOINT }) {
    // start loader
    return this.http.post(`${API_ENDPOINT}/${url}`, payload, options).pipe(map((res) => {
      return this.handleResponse(res);
    }));
  }

  /**
   *
   * @param url
   * @param postBody
   * @return json
   */
  delete({ url, payload, API_ENDPOINT = this.API_ENDPOINT }) {
    // start loader
    return this.http.delete(`${API_ENDPOINT}/${url}`, payload).pipe(map((res) => {
      return this.handleResponse(res);
    }));
  }

  /**
   *
   * @param url
   * @param putData
   * @return json
   */
  put({ url, payload, API_ENDPOINT = this.API_ENDPOINT }) {
    // start loader
    return this.http.put(`${API_ENDPOINT}/${url}`, payload).pipe(map((res) => {
      return this.handleResponse(res);
    }));
  }

  /**
   *
   * @param url
   * @param putData
   * @return json
   */
  patch({ url, payload, API_ENDPOINT = this.API_ENDPOINT }) {
    // start loader
    return this.http.patch(`${API_ENDPOINT}/${url}`, payload).pipe(map((res) => {
      return this.handleResponse(res);
    }));
  }


  /**
   *
   * @param url
   * @param file
   */
  upload(url: string, file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('files', file, file.name);
    }
    return this.post({ url, payload: formData });
  }

  /**
   *
   * @param url
   * @param data
   * @return string
   */

  formUrlParam(url, data) {
    if (!data) {
      return url;
    }
    let queryString = '';
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!queryString) {
          queryString = `?${key}=${data[key]}`;
        } else {
          queryString += `&${key}=${data[key]}`;
        }
      }
    }
    return url + queryString;
  }


  /**
   *
   * @param res
   */

  // made changes for res.json() because of errors
  handleResponse(res) {
    if (res.error) {
      // handle error
    } else {
      return res;
    }
  }

}
