<div class="threed-viewer-container">
  <header>
    <h2>3D Viewer</h2>
    <button class="eqs-button primary" *ngIf="files.length" (click)="onNewFileUploadClick()">+ Upload New File</button>
  </header>
  <div class="content">

    <!-- Upload File -->
    <div class="file-upload-container" *ngIf="!files.length">
      <zet-file-upload [type]="type" (done)="onFileUploadComplete($event)" [context]="context">
        </zet-file-upload>
    </div>

    <div class="viewer-container" *ngIf="files.length">

      <!-- 3D Viewer -->
      <div class="viewer-column">
        <zet-file-viewer
        [files]="files"
        [name]="files[0].name"
        [src]="files[0].src"
        (loaded)="onFileLoaded($event)"
        ></zet-file-viewer>
      </div>

      <div class="action-column" [formGroup]="viewerForm">
        <!-- Details -->
        <div class="action-card">
          <p class="action-title">Dimension Details</p>
          <div class="action-grid">
            <div class="action-grid-item">
              <p class="label">Length</p>
              <p class="value">{{ viewerForm.get('length.value').value }} mm</p>
            </div>
            <div class="action-grid-item">
              <p class="label">Breadth</p>
              <p class="value">{{ viewerForm.get('breadth.value').value }} mm</p>
            </div>
            <div class="action-grid-item">
              <p class="label">Height</p>
              <p class="value">{{ viewerForm.get('height.value').value }} mm</p>
            </div>
            <div class="action-grid-item">
              <p class="label">Surface Area</p>
              <p class="value">{{ viewerForm.get('surfaceArea.value').value }} mm<sup>2</sup></p>
            </div>
            <div class="action-grid-item">
              <p class="label">Volume</p>
              <p class="value">{{ viewerForm.get('volume.value').value }} mm<sup>3</sup></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
