import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpService } from '../http/http.service';
import { environment } from '../../../environments/environment';

const API_ENDPOINT = environment.apiEndPoint;

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  // Subject for the token
  currentToken = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _httpService: HttpService
  ) {}

  /**
   * Get token from FCM
   */
  requestPermission() {
    // Permission received from the user
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (token) {
          this.saveUserTokenToServer({ token }).subscribe((response) => {
            if (!response.success) throw new Error(response.message);
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Saves the firebase device registration token on the server
   * @param payload 
   * @returns 
   */
  saveUserTokenToServer(payload) {
    return this._httpService.post({
      url: `workspaces/saveAppTokenAgainstUser`,
      payload,
      API_ENDPOINT,
    });
  }

  /**
   * Get the latest audit logs from the server
   * @param params 
   * @returns 
   */
  getLatestAuditLogs(params){
    return this._httpService.get({
      url: `workspaces/getLatestAuditLogs`,
      params,
      API_ENDPOINT,
    });
  }
}
