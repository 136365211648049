/**
 * Service file for managing the side navigation
 */
import { Injectable, EventEmitter } from '@angular/core';
import { SideNavigationData } from '../model/side-navigation-data.model';
import { MenuItem } from '../model/menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class SideNavigation {
  /**
   * Store current toggle status
   */
  public isOpen: boolean;

  /**
   * Store current loading status
   */
  public isLoading: boolean;

  /**
   * Define the loader methods
   */
  public loader: { show: any; hide: any } = {
    show: () => {},
    hide: () => {},
  };

  /**
   * Store the data for side navigation
   */
  public sideNavData: SideNavigationData;

  /**
   * Record the sidebar status changes - Open / Closed
   * Can be subscribed from other components for real-time status changes
   * Emit: { isOpen: true/ false }
   */
  public stateChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Record the sidebar menu changes i.e Selected a new menu item
   * Can be subscribed from other components for real-time menu changes
   * Emit: { MenuItem }
   */
  public menuChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Record the sidebar MenuItem data population status
   * Can be subscribed from other components for real-time data changes
   * Emit: { MenuItem }
   */
  public menuLoaded: EventEmitter<any> = new EventEmitter();

  /**
   * Record the factory selection change
   * Can be subscribed from other components for real-time status changes
   * Emit: { factoryId: <id> }
   */
  public factoryChanged: EventEmitter<any> = new EventEmitter();

  constructor() {
    /** Initialize open status with False */
    this.isOpen = false;
    /** Initialize loading status with False */
    this.isLoading = false;
    /** Define the show method for loader */
    this.loader.show = () => {
      this.isLoading = true;
    };
    /** Define the hide method for loader */
    this.loader.hide = () => {
      this.isLoading = false;
    };
  }

  /**
   * Set side navigation data
   * @param data Data for rendering side navigation
   */
  setSideNavigationData(data: SideNavigationData) {
    this.sideNavData = data;
    this.menuLoaded.emit(data.menuItems || []);
  }

  /**
   * Set side navigation Menu items
   * @param data Data for rendering menu in side navigation
   */
  setMenuItems(data: MenuItem[]) {
    this.sideNavData.menuItems = data;
    this.menuLoaded.emit(data || []);
  }

  /**
   * Toggle the side menu state
   */
  toggleMenu() {
    this.isOpen = !this.isOpen;
    this.emitStateChanges();
  }

  /**
   * Manually open the menu
   */
  open() {
    this.isOpen = true;
    this.emitStateChanges();
  }

  /**
   * Manually close the menu
   */
  close() {
    this.isOpen = false;
    this.emitStateChanges();
  }

  /**
   * Emit side navigation sate changes
   */
  emitStateChanges() {
    this.stateChanged.emit({ isOpen: this.isOpen });
  }
}
