import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/http/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private _router: Router, private _userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._isAuthorized(next)) {
      return true;
    } else {
      this._router.navigateByUrl('/enquiry/list');
      return false;
    }
  }

  private _isAuthorized(route: ActivatedRouteSnapshot): boolean {
    const roles = this._userService.getUserRoles();
    const authorizedRoles = route.data.authorizedRoles;
    return roles.some(role => authorizedRoles.includes(role));
  }
}
