import { SideNavigationData } from '../model/side-navigation-data.model';
import { environment } from 'src/environments/environment';
const WORKFLOW_APP_URL = environment.workflowAppUrl;

/**
 * Instructions:
 * Define data to be rendered in side navigation
 * Data should honour the types defined in SideNavigationData Interface
 */
export const DATA: SideNavigationData = {
  title: 'ENQUIRY MANAGEMENT SYSTEM',
  shortTitle: 'EQS',
  baseIconPath: '../../../assets/icons/sidebar/',
  logout: {
    icon: 'logout.svg',
    title: 'Logout',
  },
  help: {
    icon: 'help.svg',
    title: 'Help',
  },
  support: {
    icon: 'support.svg',
    title: 'Support',
  },
  menuItems: [
    // {
    //   icon: 'attribute.svg',
    //   title: 'Attribute Master',
    //   navigateTo: {
    //     path: ['attribute'],
    //   },
    //   activeRoutes: ['/', '/attribute', '/attribute/*'],
    // },
    {
      icon: 'enquiry.svg',
      title: 'Enquiries',
      navigateTo: {
        path: ['enquiry/list'],
      },
      activeRoutes: ['/', '/enquiry/list', '/enquiry/*'],
      // Pass empty array to show this menu item for all roles
      showForRoles: []
    },
    {
      icon: 'credit-underwriting.svg',
      title: 'Credit Underwriting',
      navigateTo: {
        path: ['credit-underwriting'],
      },
      // activeRoutes: ['/', '/credit-underwriting', '/credit-underwriting/*'],
      // Pass empty array to show this menu item for all roles
      showForRoles: []
    },
    {
      icon: 'document-repository.svg',
      title: 'Document Repository',
      navigateTo: {
        path: ['document-repository/list'],
      },
      activeRoutes: ['/document-repository/list', '/document-repository/*'],
      isBeta: true,
      // Pass empty array to show this menu item for all roles
      showForRoles: []
    },
    {
      icon: 'workspace.svg',
      title: 'Workspaces',
      activeRoutes: ['/workspaces', '/workspaces/list', '/workspaces/*'],
      isBeta: true,
      navigateTo: {
        path: ['/workspaces'],
      },
    },
    {
      icon: 'query.svg',
      title: 'Clarifications',
      activeRoutes: ['/clarifications/list', '/clarifications/*'],
      isBeta: false,
      navigateTo: {
        path: ['/clarifications/list'],
      },
    },
    {
      icon: 'rfq.svg',
      title: 'RFQ',
      activeRoutes: ['/rfqs/list', '/rfqs/*'],
      isBeta: false,
      navigateTo: {
        path: ['/rfqs/list'],
      },
    },
    {
      icon: 'instant-quote.svg',
      title: 'Instant Quote',
      navigateTo: {
        path: ['instant-quote'],
      }
    },
    {
      icon: 'threed-viewer.svg',
      title: '3D Viewer',
      navigateTo: {
        path: ['3d-viewer'],
      }
    },
    {
      shortName: 'workflow',
      icon: 'zworkflowz.svg',
      title: 'Z-Workflowz',
      isBeta: true,
      navigateTo: {
        path: [WORKFLOW_APP_URL],
        externalUrl: true
      },
      showForRoles: []
    },
    {
      icon: 'template.svg',
      title: 'Watcher Control',
      navigateTo: {
        path: ['template'],
      },
      // activeRoutes: ['/', '/credit-underwriting', '/credit-underwriting/*'],
      // Pass empty array to show this menu item for all roles
      showForRoles: ['ADMIN', 'EQS_ADMIN']
    },
    {
      icon: 'eqs-insights.svg',
      title: 'EQS Insights',
      navigateTo: {
        path: ['https://sites.google.com/zetwerk.com/eqs/insights?authuser=0'],
        externalUrl: true
      },
      // Pass empty array to show this menu item for all roles
      showForRoles: []
    },
    {
      icon: 'data-team-hub.svg',
      title: 'Data Team Hub',
      navigateTo: {
        path: ['data-team-hub'],
      },
      // Pass empty array to show this menu item for all roles
      showForRoles: ['DATA_TEAM_HUB']
    },
  ],
};
