import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { compile, matcher } from '../../../utils/path-matcher.js';
import { DATA } from './data/side-navigation.data';
import { SideNavigation } from './service/side-navigation.service';
import { LoginService } from '../../../services/http/login.service';
import { LoginService as ZetLoginService } from '@zetwerk/zetui';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  /**
   * Store the current router event
   */
  private _routerEvent;
  /**
   * Store subscription
   */
  private _subscription;

  /**
   * Store hovered state
   */
  private hovered = false;

  HELP = 'HELP';
  SUPPORT = 'SUPPORT';

  constructor(public sideNav: SideNavigation, private _router: Router, private _login: LoginService, private _zetLogin: ZetLoginService ) {
    /**
     * Listen to route changes
     * To dynamically set the active status of menu
     */
    this.attachRouteLister();
    /** Listen to menu data loaded status */
    this._subscription = this.sideNav.menuLoaded.subscribe((event) => {
      /** Match the path to determine which menu to be activated */
      this.matchPathAndSetActiveMenu({ event });
    });
  }

  ngOnInit(): void {
    /**
     * Initialize data for the side navigation
     */
    this.sideNav.setSideNavigationData(DATA);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  /**
   * Attach a route lister to listen to the route changes
   */
  attachRouteLister(): void {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this._routerEvent = event;
        this.sideNav.setSideNavigationData(DATA);
      }
    });
  }

  /**
   * Handle menu item click
   * @param event The selected menu item object
   * @param position Current position of the selected menu item in list
   */
  handleMenuItemClick(event, position): void {
    this.sideNav.sideNavData.menuItems.forEach((item, index) => {
      item.active = position === index;
    });
    /** Inform service, menu has changed */
    this.sideNav.menuChanged.emit(event);
    /** Navigate to the specified route */
    if(event.navigateTo.externalUrl){
      window.open(event.navigateTo.path[0], '_blank');
    }
    else{
      this._router.navigate(event.navigateTo.path, {
        queryParams: event.navigateTo.queryParams || {},
      });
    }
  }

  /**
   * Match the route changes against the navigateTo.path and activeRoutes
   * Decide which menu item should be activated
   */
  matchPathAndSetActiveMenu({ event }) {
    /** Eliminate the query string from router.event.url */
    const url = this._routerEvent?.url?.split('?')[0] || '/';
    this.sideNav.sideNavData.menuItems.forEach((item) => {
      /** Append / to the path defined in MenuItem.navigateTo.path for match */
      const navPathString = `/${item.navigateTo.path.join('/')}`;
      /** If activeRoutes is defined ignore the navigateTo.path */
      if (item.activeRoutes) {
        const routesToSearch = item.activeRoutes.map((x) => ({ path: x }));
        const compiled = compile(routesToSearch, url);
        /**
         * Look for a match in the path supports :prams, /* wildcard matches
         * eg: If the path mentioned in activeRoutes is /demo/create =>
         * This will match /demo/create, /demo/create/anything/1234,  ie: /demo/create/*
         */
        const match =  matcher(compiled, url);
        item.active = match && match.route;
      } else {
        /** Otherwise look for a match in navigateTo.path */
        if (navPathString === '/') {
          /** Look for exact match if '/' otherwise startsWith() will match '/' as well */
          item.active = navPathString === url;
        } else {
          /** Look for path match in the beginning */
          item.active = url.startsWith(navPathString);
        }
      }
    });
  }


  reloadComponent() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['login']);
  }
  /**
   * Handle logout action
   */
  handleLogout() {
    this._zetLogin.logout();
    this._login.logout().then(result => {
      this._router.navigate(['login']);
      // this.reloadComponent();
    });
  }

  /**
   * Open URL based on type
   * @param type
   */
  openURL(type: string) {
    switch (type) {
      case this.HELP:
        window.open('https://sites.google.com/zetwerk.com/eqs');
        break;
      case this.SUPPORT:
        window.open('https://sites.google.com/zetwerk.com/zupport');
        break;
    }
  }
}
