<div [class.hidden]="!show">
  <div class="loading-overlay" *ngIf="show">
    <div *ngIf="show">
      <div class="slider">
        <div class="line"></div>
        <div class="subline inc"></div>
        <div class="subline dec"></div>
      </div>
    </div>
  </div>
</div>
