import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

const EQS_END_POINT = environment.apiEndPoint;

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {

  private bulkSelectSource = new BehaviorSubject(false);
  currentBulkSelect$ = this.bulkSelectSource.asObservable();

  private bulkActionSource = new BehaviorSubject({ selected: false, action: ''});
  currentBulkAction$ = this.bulkActionSource.asObservable();


  allowBulkActions = false;
  allowBulkStatus = false;

  isBulkSelect = false;
  showDropDown = false;
  
  constructor(private _httpService: HttpService) {}

  convertFilterQueryStringToParams(params, filterQueryString) {
    if (filterQueryString) {
      const filterQueries:String[] = Object.values(filterQueryString.split('&'));
      for (const filterQuery of filterQueries) {
        const [key, value] = filterQuery.split('=');
        params[key] = value;
      }
    }
    return params;
  }

  getWorkspaceList(workspaceTemplate, params, filterQueryString = '') {
    this.convertFilterQueryStringToParams(
      params,
      filterQueryString,
    );

    return this._httpService.get({
      url: `workspaces/${workspaceTemplate}`,
      API_ENDPOINT: EQS_END_POINT,
      params
    });
  }

  getWorkspaceFilterMeta(workspaceTemplate, params) {
    return this._httpService.get({
      url: `workspaces/${workspaceTemplate}/filter-meta`,
      API_ENDPOINT: EQS_END_POINT,
      params
    });
  }

  getWorkspaceQuickFilters(workspaceTemplate) {
    return this._httpService.get({
      url: `workspaces/${workspaceTemplate}/quick-filters`,
      API_ENDPOINT: EQS_END_POINT
    });
  }

  changeBulkSelect(bulkSelect) {
    this.bulkSelectSource.next(bulkSelect);
  }

  changeBulkAction(isBulkAction, action) {
    this.bulkActionSource.next({ selected: isBulkAction, action });
  }


}
