<div *ngIf="params.value && !['OPEN', 'CLOSED'].includes(params.value)">
    <div> <span class="status-open" [style.background-color]="
        statusMapper[params.value]?.background
          ? statusMapper[params.value].background
          : 'rgb(233, 230, 229)'
      " [style.color]="
        statusMapper[params.value]?.color
          ? statusMapper[params.value].color
          : 'rgb(144, 128, 126)'
      ">{{statusMapper[params.value]?.label || '--'}}</span></div>
</div>
<div *ngIf="params.value && ['OPEN', 'CLOSED'].includes(params.value)">
  <div *ngIf="params.value && params.value === 'OPEN' ; else elseBlock"> <span class="status-open">{{'Open'}}</span></div>
  <ng-template #elseBlock><span class="status-closed">{{'Closed'}}</span></ng-template>
</div>