import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '../http/login.service';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  constructor(private loginService: LoginService) {}

  /**
   * Initialize and set user details for mix panel
   */
  initializeMixPanel(mixpanel) {
    mixpanel.init(environment.mixpanelProjectToken, { debug: false });
    const userDetails = this.loginService.getUser();
    const userEmail = userDetails?.email;
    const userName = (userDetails?.firstName || '') + ' ' + (userDetails?.lastName || '');
    if (userEmail && userName) {
      mixpanel.identify(userEmail);
      mixpanel.people.set({
        $email: userEmail,
        $name: userName,
      });
    }
  }

  /**
   * Track Page View with all meta data needed
   */
  trackPageViewsMixpanel({ mixpanel, previousUrl, eventUrl }) {
    let { url: previousUrlFormatted } = this.getUrlForMixpanel(
      previousUrl?.split('/') || []
    );
    let { url: eventUrlFormatted, zUUID } = this.getUrlForMixpanel(
      eventUrl?.split('/') || []
    );
    mixpanel.track('Page View', {
      ...(previousUrlFormatted ? { zPreviousUrl: previousUrlFormatted } : {}),
      ...(eventUrlFormatted ? { zEventUrl: eventUrlFormatted } : {}),
      ...(zUUID ? { zUUID } : {})
    });
  }

  /**
   * Formats Urls For MixPanel
   */
  getUrlForMixpanel(url: string[]) {
    let zUUID = '';
    let urlToArray: any = [];
    let alphabetRegex = new RegExp('^[a-zA-Z-]+$');
    let alphanumericRegex = new RegExp('^[a-zA-Z0-9]+$');
    for (let i = 0; i < url.length; i++) {
      let partial = url[i];
      if (i === url.length - 1) partial = url[i]?.split('?')?.[0]; // Remove query params
      if (alphabetRegex.test(partial)) {
        urlToArray.push(partial);
      } else if (alphanumericRegex.test(partial) && zUUID === '') {
        // Assign to zUUID only if its empty string. For now we are sending only 1 zUUID.
        zUUID = partial;
      }
    }
    return {
      url: urlToArray?.join('/'),
      zUUID
    };
  }
}
