import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';

const EQS_END_POINT = environment.apiEndPoint;

@Injectable({
  providedIn: 'root'
})
export class CreditUnderWritingService {
  constructor(
    private _httpService: HttpService,
  ) { }


  verifyExcel(payload) {
    return this._httpService.post({
      url: 'credit-underwriting/verify',
      payload,
      API_ENDPOINT: EQS_END_POINT,
    });
  }
  createUnderwriting(payload) {
    return this._httpService.post({
      url: 'credit-underwriting',
      payload,
      API_ENDPOINT: EQS_END_POINT,
    });
  }

}
