import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'app-enquiry-query-number-cell',
  templateUrl: './enquiry-query-number-cell.component.html',
  styleUrls: ['./enquiry-query-number-cell.component.scss'],
})
export class EnquiryQueryNumberCellComponent implements ICellCmp {
  params;
  columnDef;
  element;

  constructor() {}

  init(params: any) {}
}
