import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SimpleModalModule } from 'ngx-simple-modal';

import { IndiaCurrencyPipe } from '../../pipes/india-currency.pipe';
import { IndianNumberFormatPipe } from '../../pipes/indian-number-format.pipe';

import { InputErrorComponent } from './input-error/input-error.component';
import { LayoutComponent } from './layout/layout.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { CustomPopupComponent } from './custom-popup/custom-popup.component';
import { LoaderComponent } from './loader/loader.component';
import { SearchComponent } from './search/search.component';
import { SubmitEnquiryRemarkPopupComponent } from './submit-enquiry-remark-popup/submit-enquiry-remark-popup.component';
import { FilesComponent } from './files/files.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TruncateFilenamePipe } from 'src/app/pipes/truncate-filename.pipe';
import { EnquiryTypeDropdownComponent } from './enquiry-type-dropdown/enquiry-type-dropdown.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { EqsTagsComponent } from './eqs-tags/eqs-tags.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { StatusDropdownComponent } from './status-dropdown/status-dropdown.component';
import { ConfidenceLevelDropdownComponent } from './confidence-level-dropdown/confidence-level-dropdown.component';
import { StatusReasonComponent } from './status-reason/status-reason.component';
import { JumpToComponent } from './jump-to/jump-to.component';
import { DownloadDumpDropdownComponent } from './download-dump-dropdown/download-dump-dropdown.component';
import { FilterGridModule } from './filter-grid/filter-grid.module';
import { OfferHistoryComponent } from './offer-history/offer-history.component';
import { NgToggleModule } from 'ng-toggle-button';
import { RequestRevisionPopupComponent } from './request-revision-popup/request-revision-popup.component';
import { EllipsifyMeDirective } from 'src/app/directives/ellipsify-me.directive';
import { TagUsersInputComponent } from './submit-enquiry-remark-popup/tag-users-input/tag-users-input.component';
import { TimelineStepperComponent } from './timeline-stepper/timeline-stepper.component';
import { FileViewerModule, StepperModule, ZetAppSharedModule, FileUploadModule, FILE_VIEWER_CONFIG } from '@zetwerk/zetui';
import { BulkActionsDropdownComponent } from './bulk-actions-dropdown/bulk-actions-dropdown.component';
import { UserPreferencesPopupComponent } from './user-preferences-popup/user-preferences-popup.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { ShowForRolesDirective } from 'src/app/directives/show-for-roles.directive';
import { ZetListModule } from '@zetwerk/zet-list';
import { NotificationComponent } from './notification/notification.component';
import { DocumentTypeDropdownComponent } from './document-type-dropdown/document-type-dropdown.component';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { FileViewerPopupComponent } from './file-viewer-popup/file-viewer-popup.component';
import { QueryTypeDropdownComponent } from './query-type-dropdown/query-type-dropdown.component';

import { EnquiryQueryNumberCellComponent } from './zet-table/components/enquiry-query-number-cell/enquiry-query-number-cell.component';
import { SubtextDateCellComponent } from './zet-table/components/subtext-date-cell/subtext-date-cell.component';
import { QueryTypeCellComponent } from './zet-table/components/query-type-cell/query-type-cell.component';
import { QueryStatusCellComponent } from './zet-table/components/query-status-cell/query-status-cell.component';
import { QueryDateDurationCellComponent } from './zet-table/components/query-date-duration-cell/query-date-duration-cell.component';
import { GenericRfqStatusCellComponent } from './zet-table/components/generic-rfq-status-cell/generic-rfq-status-cell.component';
import { GenericRfqDateCellComponent } from './zet-table/components/generic-rfq-date-cell/generic-rfq-date-cell.component';
import { ClickOutsideDirective } from 'src/app/directives/click-outside.directive';
import { FilesUploaderComponent } from './files-uploader/files-uploader.component';

@NgModule({
  declarations: [
    IndiaCurrencyPipe,
    InputErrorComponent,
    LayoutComponent,
    SideNavigationComponent,
    CustomPopupComponent,
    LoaderComponent,
    SearchComponent,
    SubmitEnquiryRemarkPopupComponent,
    FilesComponent,
    TruncateFilenamePipe,
    EnquiryTypeDropdownComponent,
    NoDataFoundComponent,
    EqsTagsComponent,
    StatusDropdownComponent,
    ConfidenceLevelDropdownComponent,
    AuditLogsComponent,
    StatusReasonComponent,
    JumpToComponent,
    OfferHistoryComponent,
    DownloadDumpDropdownComponent,
    RequestRevisionPopupComponent,
    IndianNumberFormatPipe,
    EllipsifyMeDirective,
    TagUsersInputComponent,
    TimelineStepperComponent,
    BulkActionsDropdownComponent,
    UserPreferencesPopupComponent,
    FileViewerComponent,
    ShowForRolesDirective,
    ClickOutsideDirective,
    NotificationComponent,
    DocumentTypeDropdownComponent,
    SafeHtmlPipe,
    FileViewerPopupComponent,
    QueryTypeDropdownComponent,
    EnquiryQueryNumberCellComponent,
    SubtextDateCellComponent,
    QueryTypeCellComponent,
    QueryStatusCellComponent,
    QueryDateDurationCellComponent,
    GenericRfqStatusCellComponent,
    GenericRfqDateCellComponent,
    FilesUploaderComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TabsModule.forRoot(),
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    FilterGridModule,
    NgToggleModule,
    SimpleModalModule,
    StepperModule,
    FileViewerModule,
    FileUploadModule,
    ZetAppSharedModule,
    ZetListModule.forRoot({
      frameworkComponents: {
        'enquiryQueryNumberCellRenderer': EnquiryQueryNumberCellComponent,
        'subtextDateCellRenderer': SubtextDateCellComponent,
        'queryTypeCellRenderer': QueryTypeCellComponent,
        'queryStatusCellRenderer': QueryStatusCellComponent,
        'queryDateDurationCellRenderer': QueryDateDurationCellComponent,
        'genericRfqStatusCellRendered': GenericRfqStatusCellComponent,
        'genericRfqDateCellRendered': GenericRfqDateCellComponent
      }
  })
  ],
  providers:[
    {
      provide: FILE_VIEWER_CONFIG,
      useValue: {
        baseUrl: ''
      }
    }
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TooltipModule,
    SimpleModalModule,
    IndiaCurrencyPipe,
    InputErrorComponent,
    CustomPopupComponent,
    ModalModule,
    TabsModule,
    LoaderComponent,
    SearchComponent,
    SubmitEnquiryRemarkPopupComponent,
    FilesComponent,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    TruncateFilenamePipe,
    EnquiryTypeDropdownComponent,
    NoDataFoundComponent,
    EqsTagsComponent,
    StatusDropdownComponent,
    AuditLogsComponent,
    ConfidenceLevelDropdownComponent,
    StatusReasonComponent,
    JumpToComponent,
    FilterGridModule,
    OfferHistoryComponent,
    DownloadDumpDropdownComponent,
    NgToggleModule,
    RequestRevisionPopupComponent,
    IndianNumberFormatPipe,
    EllipsifyMeDirective,
    TagUsersInputComponent,
    TimelineStepperComponent,
    StepperModule,
    BulkActionsDropdownComponent,
    FileViewerComponent,
    ShowForRolesDirective,
    ClickOutsideDirective,
    ZetListModule,
    ZetAppSharedModule,
    NotificationComponent,
    DocumentTypeDropdownComponent,
    SafeHtmlPipe,
    QueryTypeDropdownComponent,
    EnquiryQueryNumberCellComponent,
    SubtextDateCellComponent,
    QueryTypeCellComponent,
    QueryStatusCellComponent,
    QueryDateDurationCellComponent,
    GenericRfqStatusCellComponent,
    FilesUploaderComponent
  ],
  entryComponents: [CustomPopupComponent, SubmitEnquiryRemarkPopupComponent],
})
export class SharedModule { }
