export const environment = {
  configuration: 'production',
  production: true,
  local: false,
  maintenance: false,
  appUrl: 'https://eqs.zetwerk.com',
  versionCheckURL: 'https://eqs.zetwerk.com/version.json',
  googleClientId: '533773529183-97ufurmdfs5q12phba0ksssqt6g8maqa.apps.googleusercontent.com',
  froalaKey: 'xGE6oC4G3B3C11A6C7fayiizrjE5f1khzG2B1A2C2D6B1A1C4E1E4==',
  mixpanelProjectToken: 'd76b427fea05bf99ef4ba8826698cf2b',
  apiEndPoint: 'https://api.zetwerk.com/eqsv2/api/v1/',
  authEndPoint: 'https://api.zetwerk.com/v1/',
  supplierApiEndPoint: 'https://api.zetwerk.com/suppliers-v2/api/v2',
  userApiEndPont: 'https://api.zetwerk.com/user/v1/',
  cmsApiEndPoint: 'https://api.zetwerk.com/cms/api/v1/',
  catalogApiEndPoint: 'https://api.zetwerk.com/catalogue/api/v1/',
  omsApiEndPoint: 'https://api.zetwerk.com/oms/v1',
  oldEqsUIEndpoint: 'https://eqsv1.zetwerk.com/',
  customerApiEndPoint: 'https://api.zetwerk.com/customer/v1/',
  rfqApiEndPoint: 'https://api.zetwerk.com/rfq-v2/api/v1/',
  workflowAppUrl: 'https://workflow.zetwerk.com',
  zisoEndpoint: 'https://api.zetwerk.com/ziso/v2',
  commonMastersAPIEndPoint: 'https://api.zetwerk.com/common-masters/api/v1/',
  firebaseConfig: {
    apiKey: "AIzaSyCxUsaHYK6Wbo1HdER-Pr0uQvgvkMdZ6k4",
    authDomain: "eqs-prod-a545b.firebaseapp.com",
    projectId: "eqs-prod-a545b",
    storageBucket: "eqs-prod-a545b.appspot.com",
    messagingSenderId: "305482684491",
    appId: "1:305482684491:web:efa5f881df392b8abc15ce",
    measurementId: "G-NTS9JZHWNE"
  },
  sentryTracingOrigins: ['https://api.zetwerk.com'],
  sentryDsn: 'https://b42acf36cd68422dac5004f59216efad@o200139.ingest.sentry.io/6584585',
  googleAnalyticsId: 'G-G1RVD1S3WD'
};
