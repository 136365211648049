import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import * as localForage from 'localforage';
import { environment } from 'src/environments/environment';

const AUTH_END_POINT = environment.authEndPoint

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authToken: any;
  private user: any;

  constructor(private httpService: HttpService) {
  }

  getAuthToken() {
    if (!this.authToken) {
      this.authToken = localStorage.getItem('token');
    }
    return this.authToken;
  }

  /**
   * @param token
   * set the token
   */

  setAuthToken(token) {
    this.authToken = token;
    return localStorage.setItem('token', token);
  }

  /**
   * @param userObject={
   *
   * }
   * set the user object
   */

  async setUser(userObject) {
    this.user = userObject;
    return localStorage.setItem('user', JSON.stringify(userObject));
  }

  /**
   *
   * @param token
   * login through Google sign-in method
   */

  login(token) {
    const payload = {
      token
    };
    return this.httpService.post({
      url: 'authenticate', payload,
      API_ENDPOINT: AUTH_END_POINT
    });
  }

  /**
   * Clear Local storage and logout
   */
  async logout(){
    await localForage.clear();
    localStorage.clear();
    this.authToken = undefined;
  }

  isUserAdmin() {
    const user = JSON.parse(localStorage.getItem('user') || '');
    return user?.roleDetails.some(role => ['ADMIN', 'EQS_ADMIN'].includes(role.name));
  }

  /**
   * Get User Id
   * @returns string user ObjectId
   */
  getUserId() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user && user._id;
  }

  /**
   * Get logged in user details
   */
  getUser() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

}
