import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

const OMS_API_END_POINT = environment.omsApiEndPoint;
@Injectable({
  providedIn: 'root',
})
export class OmsService {
  slug: any;

  constructor(private _httpService: HttpService) {}

  getZetwerkBankDetails() {
    return this._httpService.get({
      url: `bank-account`,
      API_ENDPOINT: OMS_API_END_POINT,
      headers: new HttpHeaders({
        'x-company-slug': 'zetwerk',
      }),
    });
  }

  getZetwerkGSTAndAddressDetails() {
    return this._httpService.get({
      url: `zetwerk-gst/config-list`,
      API_ENDPOINT: OMS_API_END_POINT,
      headers: new HttpHeaders({
        'x-company-slug': 'zetwerk',
      }),
      params: {
        selectFields: 'name gst address city state pincode',
      },
    });
  }

  setCompanySlug(slug) {
    this.slug = slug;
  }

  getCompanySlug() {
    return this.slug;
  }

  getContracts(params: any = {}) {
    return this._httpService.get({
      url: 'contracts',
      params,
      headers: new HttpHeaders({
        'x-company-slug': 'zetwerk',
      }),
      API_ENDPOINT: OMS_API_END_POINT,
    });
  }
}
