import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'app-query-type-cell',
  templateUrl: './query-type-cell.component.html',
  styleUrls: ['./query-type-cell.component.scss'],
})
export class QueryTypeCellComponent implements ICellCmp {
  params;
  columnDef;
  element;

  constructor() {}

  init(params: any) {}
}
