import { IFileUploadConfig } from "@zetwerk/zetui";
import { RenderTextMode } from "ng2-pdf-viewer";
import { environment } from "src/environments/environment";
import { defaultFileTypes } from "../components/shared/files/data/mime.data";

const API_ENDPOINT = environment.apiEndPoint;

//TODO: Remove once ZETUI accepts all these config as Input.
export const fileUploadConfig: IFileUploadConfig = {
    acceptedFileTypes: defaultFileTypes,
    maxFiles: 5,
    maxSize: '50mb',
    signedUrlEndpoint: `${API_ENDPOINT}/files/signed-upload-urls`,
    registerFileEndpoint: `${API_ENDPOINT}/files?isProgressiveRequired=false&isThumbnailRequired=true&is3DFileUpload=true&isPdfThumbnailRequired=false`
};

export const pdfOptions = {
    autoresize: false,
    cMapsUrl: '',
    externalLinkTarget: 'blank',
    fitToPage: false,
    originalSize: true,
    renderText: false,
    renderTextMode: RenderTextMode.DISABLED,
    rotation: 0,
    showAll: true,
    showBorders: false,
    stickToPage: true
};

export const imgOption = {
    download: false,
    idContainer: 'idOnHTML',
    loadOnInit: true,
    showPDFOnlyOption: false
};

export const SURFACE_FINISHES = [
    {
        label: 'Clear Anodization',
        value: 'Clear Anodization'
    },
    {
        label: 'Powder Coating',
        value: 'Powder Coating'
    },
    {
        label: 'Black Anodization',
        value: 'Black Anodization'
    },
    {
        label: 'Mill Finish',
        value: 'Mill Finish'
    }
];

export const COMPLEXITIES = [
    {
        label: 'Low',
        value: 'Low'
    },
    {
        label: 'Medium',
        value: 'Medium'
    },
    {
        label: 'High',
        value: 'High'
    }
];

export const COMPLEXITY_MAPPER = {
    "LOW": {
        color: '#228B3E',
        background: '#E9F3EC'
    },
    "MEDIUM": {
        color: '#E68500',
        background: '#FEF3E5'
    },
    "HIGH": {
        color: '#C90000',
        background: '#FAE5E5'
    }
};
