import {
  Env,
  IZetAppCoreModule,
  ILoginConfig,
  LoginType,
} from '@zetwerk/zetui';
import { environment } from '../../environments/environment';

export const loginConfig: ILoginConfig = {
  title: {
    text: 'Zetwerk EQS',
    subText: 'Login to',
  },
  background: {
    backgroundImg: '../../../../assets/images/LoginScreen.png',
  },
  loginProviders: [
    { id: LoginType.EMAIL_OTP },
    { id: LoginType.GOOGLE, clientId: environment.googleClientId },
  ],
};

export const zetAppModuleConfig: IZetAppCoreModule = {
  appName: 'eqs-ui-v2',
  env: Env[environment.configuration],
  featuresEnabled: {
    login: true,
    loader: false,
    auth: true,
    deployment: true,
    http: false,
  },
  login: {
    config: loginConfig,
  },
  auth: {
    config: { authUrl: environment.authEndPoint },
  },
};
