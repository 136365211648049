import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './components/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './interceptor/interceptor';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/general/login/login.component';
import { QuillModule } from 'ngx-quill';
import { DummyComponent } from './components/general/dummy/dummy.component';
import { CreditUnderwritingComponent } from './components/credit-underwriting/credit-underwriting.component';
import { InstantQuoteComponent } from './components/instant-quote/instant-quote.component';
import { FILE_UPLOAD_CONFIG, LoginService, ZetAppCoreModule, browserEnvProviders } from '@zetwerk/zetui';
import { zetAppModuleConfig } from './constants/zetapp-pkg-ui';
import { fileUploadConfig } from './constants/instantQuote';
import { TemplateComponent } from './components/general/template/template.component';

import { MessagingService } from './services/notification/messaging.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { DocumentRepositoryComponent } from './components/document-repository/document-repository.component';
import { QueryComponent } from './components/query/query.component';
import { GenericRfqComponent } from './components/generic-rfq/generic-rfq.component';
import { OtpLoginComponent } from './components/general/otp-login/otp-login.component';
import { EQSLoginService } from './services/custom/eqs-login.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OtpLoginComponent,
    DummyComponent,
    CreditUnderwritingComponent,
    InstantQuoteComponent,
    CreditUnderwritingComponent,
    TemplateComponent,
    DocumentRepositoryComponent,
    QueryComponent,
    GenericRfqComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    QuillModule.forRoot(), 
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ZetAppCoreModule.forRoot(zetAppModuleConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { 
      provide: FILE_UPLOAD_CONFIG, 
      useValue: fileUploadConfig 
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    MessagingService,
    ...browserEnvProviders,
    { provide: LoginService, useClass: EQSLoginService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
