<!-- Side Navigation Container-->
<div class="navigation-container" (mouseenter)="sideNav.toggleMenu()" (mouseleave)="sideNav.toggleMenu()">
  <!-- Logo section -->
  <section class="logo-section">
    <div class="logo-container">
      <div class="logo"></div>
    </div>
    <div class="menu-close">
      <div class="close-icon" (click)="sideNav.toggleMenu()"></div>
    </div>
  </section>
  <!-- ./Logo section -->

  <!-- ./Menu toggle section -->
  <!-- Info section -->
  <section class="info-section">
    <div class="title">{{ sideNav.isOpen ? sideNav.sideNavData.title :  sideNav.sideNavData.shortTitle}}</div>
  </section>
  <!-- ./Info section -->
  <!-- ./Menu item section -->
  <section *ngIf="!sideNav.isLoading">
    <!-- Menu section -->
    <section class="menu-section">
      <ng-container  *ngFor="let item of sideNav.sideNavData.menuItems; let i = index">
      <hr *ngIf="(i == (sideNav.sideNavData.menuItems.length-2) ) || (item?.shortName === 'workflow')" class="divider"/>
      <div
        class="item"
        [class.active]="item.active"
        placement="right"
        (click)="handleMenuItemClick(item, i)"
        *appShowForRoles="item?.showForRoles"
      >
        <div class="icon">
          <img
            src="{{ sideNav.sideNavData.baseIconPath + item.icon }}"
            *ngIf="!item.active" alt="nav-icon"
          />
          <img
            src="{{ sideNav.sideNavData.baseIconPath + 'active-' + item.icon }}"
            *ngIf="item.active" alt="nav-active-icon"
          />
        </div>
        <div class="title">{{ item.title }} &nbsp;<span *ngIf="item.isBeta" class="badge badge-secondary beta">Beta</span></div>
      </div>
    </ng-container>
    </section>
    <!-- ./Menu section -->
  </section>
  <!-- ./Menu item section -->
  <!-- Loading section -->
  <section class="loading-section" *ngIf="sideNav.isLoading">
    <div class="loading">
      <div class="loader"></div>
    </div>
  </section>
  <!-- ./Loading section -->
  <!-- Help Section -->
  <section class="help-section">
    <div class="help" (click)="openURL(HELP)">
      <div class="icon">
        <img
          src="{{
            sideNav.sideNavData.baseIconPath + sideNav.sideNavData.help.icon
          }}"
          alt="logout"
        />
      </div>
      <div class="title">{{ sideNav.sideNavData.help.title }}</div>
    </div>
  </section>
  <!-- ./Help Section -->
  <!-- Support Section -->
  <section class="support-section">
    <div class="support" (click)="openURL(SUPPORT)">
      <div class="icon">
        <img
          src="{{
            sideNav.sideNavData.baseIconPath + sideNav.sideNavData.support.icon
          }}"
          alt="support"
        />
      </div>
      <div class="title">{{ sideNav.sideNavData.support.title }}</div>
    </div>
  </section>
  <!-- ./Support Section -->
  <!-- Logout Section -->
  <section class="logout-section">
    <div class="logout" (click)="handleLogout()">
      <div class="icon">
        <img
          src="{{
            sideNav.sideNavData.baseIconPath + sideNav.sideNavData.logout.icon
          }}"
          alt="logout"
        />
      </div>
      <div class="title">{{ sideNav.sideNavData.logout.title }}</div>
    </div>
  </section>
  <!-- ./Logout Section -->
</div>
<!-- ./Side Navigation Container-->
