import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { GAnalyticsService } from './services/g-analytics.service';
import mixpanel from 'mixpanel-browser';
import { MixPanelService } from 'src/app/services/custom/mixpanel.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isProduction = environment.production;
  
  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private _gAnalytics: GAnalyticsService,
    private mixPanelService: MixPanelService
  ) {
    this._gAnalytics.initialiseGAConfig();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._gAnalytics.pageViewChanged(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this.mixPanelService.initializeMixPanel(mixpanel);
    this.subscribeRouterEvents();
  }

  /**
   * Function to listen for route url changes
   * for analytics purpose
   */
  subscribeRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((e: any) => e instanceof RoutesRecognized),
          startWith(''), // emitting first empty value to fill-in the buffer
          pairwise()
        )
        .subscribe((event: any) => {
          const previousUrl = event?.[0]?.urlAfterRedirects || '';
          const eventUrl = event?.[1]?.urlAfterRedirects || '';
          if (previousUrl) localStorage.setItem('previousUrl', previousUrl);
          this.mixPanelService.trackPageViewsMixpanel({
            mixpanel,
            previousUrl,
            eventUrl
          });
        })
    );
  }
}
