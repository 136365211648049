import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginType } from '@zetwerk/zetui';
import { GAnalyticsService } from 'src/app/services/g-analytics.service';
import { LoginService } from 'src/app/services/http/login.service';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.scss'],
})
export class OtpLoginComponent implements OnInit {
  loginType = LoginType;
  data;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private googleAnalyticsService: GAnalyticsService
  ) {}

  ngOnInit() {}

  onLogin(response: any): void {
    this.data = response;
    this.loginService.setAuthToken(this.data?.data?.tokens?.zetAuthToken);
    this.loginService.setUser(this.data.userObject);
    this.googleAnalyticsService.userLoggedIntoSystem();
    setTimeout(() => {
      this.redirect();
    }, 500);
  }

  redirect() {
    this.router.navigateByUrl('/');
  }
}
