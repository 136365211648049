import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/http/user.service';

@Directive({
  selector: '[appShowForRoles]'
})
export class ShowForRolesDirective {

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, private _userService: UserService) { }

  @Input('appShowForRoles') set showForRoles(rolesList: Array<string>) {
    const roles = rolesList || [];
    if (roles.length > 0) {
      this.roleChecker(roles)
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    }
  }

  private roleChecker(showFor: Array<string>) {
    const userRoles: Array<string> = this._userService.getUserRoles();

    if (userRoles.length === 0) {
      this.viewContainerRef.clear()
    } else {
      const isShow = userRoles.some(role => showFor.includes(role))
      return isShow ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
    }
  }
}
