import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { getMoment } from 'src/app/utils/helper';

@Component({
  selector: 'app-generic-rfq-date-cell',
  templateUrl: './generic-rfq-date-cell.component.html',
  styleUrls: ['./generic-rfq-date-cell.component.scss']
})
export class GenericRfqDateCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {

  }

  getMoment(timestamp) {
    return getMoment(timestamp);
  }

}
