import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { LoginService } from "./http/login.service";

@Injectable({
  providedIn: 'root'
})
export class GAnalyticsService {
  googleAnalyticsId = environment.googleAnalyticsId;

  constructor(private _auth: LoginService) {}

  /**
   * Initialize Google analytics Service
   */
  initialiseGAConfig() {
    const gtag = (<any>window).gtag;

    const { appUrl } = environment;

    gtag('config', this.googleAnalyticsId, {
      send_page_view: false,
      custom_map: {
        dimension1: 'User Identifier',
        dimension2: 'User Group'
      },
      linker: {
        domains: [appUrl]
      }
    });
  }

  /**
   * Send Page View 
   * @param url string  
   */
  async pageViewChanged(url) {
    const gtag = (<any>window).gtag;

    const loggedInUserId = await this._auth.getUserId();
    gtag('event', 'page_view', {
      send_to: this.googleAnalyticsId,
      page_path: url,
      'User Identifier': loggedInUserId,
      user_id: loggedInUserId
    });
  }

  /**
   * Set Google Analytics user Id when user 
   * logged into the system
   */
  userLoggedIntoSystem() {
    const userId = this._auth.getUserId();
    const gtag = (<any>window).gtag;
    gtag('config', this.googleAnalyticsId, {
      send_page_view: false,
      custom_map: {
        dimension1: 'User Identifier'
      }
    });
    gtag('set', { user_id: userId, send_to: this.googleAnalyticsId });
    gtag('event', 'page_view', {
      send_to: this.googleAnalyticsId,
      event_category: 'EQS',
      event_label: 'EQS Opened',
      'User Identifier': userId,
      user_id: userId
    });
  }

}