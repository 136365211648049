<div class="login-container">
  <div class="logo">
    <img src="assets/images/logo.svg" height="35" alt="logo"/>
  </div>
  <div class="login-view-container">
    <div class="g-login">
      <div class="form-container">
        <div>
          <h2 class="login-subHeading">Sign In to</h2>
          <p class="login-Heading">Enquiry Quotation System [EQS]</p>
        </div>
        <div class="sign-in">
          <zet-google-login-btn (login)="onLogin($event)"></zet-google-login-btn>
        </div>
        <div class="request">
          <span class="signin-access">Trouble signing in? </span><span class="request-access" (click)="requestAccess()"> Request Access</span>
        </div>
        <div class="unimacts">
          <span>
            If you're Unimacts user,
          </span><a href="/otp-login">Login here using OTP</a>
        </div>
      </div>
    </div>
  </div>
  <div class="image-content">
    <h1 class="title">Enquiry Quotation System</h1>
    <p class="sub-title">The comprehensive tool for managing all enquiries</p>
  </div>
</div>