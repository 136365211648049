import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterGridComponent } from './filter-grid/filter-grid.component';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';



@NgModule({
  declarations: [FilterGridComponent, FilterChipsComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [NgSelectModule, FilterGridComponent, FilterChipsComponent]
})
export class FilterGridModule { }
