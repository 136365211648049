import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/http/login.service';
import { GAnalyticsService } from 'src/app/services/g-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  data;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private googleAnalyticsService: GAnalyticsService
  ) {}

  ngOnInit(): void {
    if (this.loginService.getAuthToken()) {
      this.redirect();
    }
  }

  onLogin(response: any): void {
    this.data = response;
    this.loginService.setAuthToken(this.data.tokens.zetAuthToken);
    this.loginService.setUser(this.data.userObject);
    this.googleAnalyticsService.userLoggedIntoSystem();
    setTimeout(() => {
      this.redirect();
    }, 500);
  }

  redirect() {
    this.router.navigateByUrl('/');
  }

  requestAccess() {
    window.open('https://zetwerk.freshservice.com/support/catalog/items/61', "_blank");
  }
}
