<div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.CREATE"> <span class="status-create">{{'Create'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.UPDATE"> <span class="status-update">{{'Update'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.DELETE"> <span class="status-delete">{{'Delete'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.DRAFT"> <span class="status-draft">{{'Draft'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.RFQ_SENT"> <span class="status-rfq-sent">{{'RFQ Sent'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.EDIT_STATUS"> <span class="status-edit-status">{{'Edit Status'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.DRAWINGS_VIEWED"> <span class="status-drawings-viewed">{{'Drawings Viewed'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.SUPPLIER_NOTIFIED"> <span class="status-supplier-notified">{{'Supplier Notified'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.RFQ_CANCELLED"> <span class="status-rfq-cancelled">{{'RFQ Cancelled'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.SUPPLIER_RESPONDED"> <span class="status-supplier-responded">{{'Supplier Responded'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.QUOTE_ACCEPTED"> <span class="status-quote-accepted">{{'Quote Accepted'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.QUOTE_REJECTED"> <span class="status-quote-rejected">{{'Quote Rejected'}}</span></div>
  <div *ngIf="params.value && params.value === genericRFQStatusMapper.PARTIALLY_QUOTED"> <span class="status-partially-quoted">{{'Paritally Quoted'}}</span></div>
</div>