import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-data-team-hub',
  templateUrl: './data-team-hub.component.html',
  styleUrls: ['./data-team-hub.component.scss'],
})
export class DataTeamHubComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
