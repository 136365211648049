import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { RFQ_STATUS_MAPPER } from 'src/app/constants/rfqStatusTypes';

@Component({
  selector: 'app-generic-rfq-status-cell',
  templateUrl: './generic-rfq-status-cell.component.html',
  styleUrls: ['./generic-rfq-status-cell.component.scss']
})
export class GenericRfqStatusCellComponent implements ICellCmp  {
  params;
  columnDef;
  element;
  genericRFQStatusMapper = RFQ_STATUS_MAPPER;
  constructor() {}
  init(params: any) {}
}
